import React from "react";
import { Link } from "react-router-dom";
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup,
    Button,
    UncontrolledTooltip
    // Table
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faEye,
    faCloudSun, 
    faFireAlt,
    faExternalLink,
    faTags,
    faFilter,
    faLink,
    faGlobeAmericas,
    faPlusCircle,
    faFire,
    faMugHot,
    faSnowman,
    faSnowflake
} from '@fortawesome/free-solid-svg-icons';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import moment from "moment";
import _ from 'lodash';
import Table from '../../../utils/MaterialTable';
import TableIcon from '../../../../utils/TableIcon';
import DashboardElement from '../../../../utils/DashboardElement';

import axios from 'axios';
import { API_DOMAIN } from '../../../../../appConfig'; 
import { axiosDefaults } from '../../../../../appConfigaxios'; 
import IconButton from "../../../IconButton/IconButton";
import { genThermoIcon } from "./PriorityIconsToggle";
import { genAlertIcon } from "./AlertIconsToggle";
import trad from '../../../../../srcincorpo/trad/trad';
import { getLang } from "../../../../../composables/genLang";
const BACKEND1 = process.env.REACT_APP_API_URLX;

class CenterSectionclose extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data:[],
            postErrorMsg: ''
        };
    }

    componentDidMount(){
        axiosDefaults(null);
        const this2 = this;
        axios.get(
            BACKEND1+'/leads',
            {
                params: {
                    //src: "desk",
                    showtrans: 3,
                    forceStatus:3, 
                    // page:2,
                    // limit:6,
                    // newlead:1 pour obtenir des un nouveau lead
                },
                //headers: {
                    //'Content-Type': 'application/json',
                    // 'x-jwt-token': localStorage.getItem('token'),
                    //'auth-id': localStorage.getItem('user_id'),
                    //'Authorization': "Bearer " + localStorage.getItem('token')
                //}
            }
        )
        .then((status) => {
            // console.log(status)
            let {data} = status.data;
            if(data && data.neoleads){
                let {neoleads} = data;

                let arr = _.map(
                    neoleads,
                    (e) => {
                        //console.log(neoleads)
                        return {
                            ...e,
                            ...e.definition,
                            id: e.id,
                            ticket_id: e.ticket_id,
                            link: e.ticket_id,
                            domain: e.domaine,
                            updated_at:moment().format('LLL')
                        }
                    }
                )
                // console.log('arr',arr,neoleads)
                this.setState({
                    data:arr
                })
                const leads = JSON.stringify(arr.map(i => i.id));
                // sessionStorage.setItem('leads', leads);
                //console.log(leads)
            }
        },(error) =>{
            this.setState({
                redirect:'/login'
            })
        })
    }

    genLang = (text) => {
        const lang = getLang();
        return trad['neoleads'][text][lang]
    }

    // ComponentWillUnmount remove sessionStorage

    

    render(){
        const leads = this.state.data.map(i => i.id)

        if(this.state.redirect){

            // if(this.state.redirect === 'expiration'){
            //     return(
            //         <Redirect
            //             to={{
            //                 pathname: "/expiration"
            //                 // state: { referrer: props.location.pathname }
            //             }}
            //         />
            //     )    
            // }
            return(
                <Redirect
                to={{
                    pathname: this.state.redirect,
                    // search: window.location.search+"?return="+window.location.pathname,
                    // state: { referrer: props.location.pathname }
                }}
            />
            )
        }

        const columns = [
            {
                Header: 'Lead #',
                accessor: 'id',
            },
            {
                Header: 'Alerte',
                accessor: 'alert',
            },
            // {
            //     Header: this.genLang('origin'),
            //     accessor: 'ticket_tags',
            // },
            {
                Header: this.genLang("domain"),
                accessor: 'domain',
            },
            {
                Header: this.genLang('subject'),
                accessor: 'ticket_subject',
            },
            {
                Header: this.genLang('priority'),
                accessor: 'thermoIcon'
            },
            
            // {
            //     Header: 'Modif',
            //     accessor: 'updated_at',
            // },
            
            {
                Header: this.genLang('created'),
                accessor: 'created',
            },
            
            {
                Header: 'Link',
                accessor: 'linkIcon'
            }
        ];

        return (<>
        {/* <Row onClick={() => console.log(this.state)}>
            <Col md={12} style={{paddingBottom: '1rem'}}>
                <h1 style={{ color: "#0052BB" }} className='text-align-center m-t-15 element-header'>Leads</h1>
            </Col>
        </Row>
        <Row>
            <Col md={12} > */}

                <DashboardElement
                    col={12}
                    // {`${this.genLang('myNextLeads')}`}
                    title={<>Mes Leads Fermés/Close <FontAwesomeIcon icon={faTags} open={true} /></>}
                    toggle={false}
                >
                    <Row>
                        <Col md={12}>
                            {this.state.postErrorMsg && <Alert toggle={() => this.setState({postErrorMsg: ""})} style={{marginTop: '1rem'}} color="danger">{this.state.postErrorMsg}</Alert>}
                            <Nav
                                style={{
                                    paddingTop: "0",
                                    marginBottom: "10px"
                                }}
                            >
                                {/* <div>{`${this.genLang('numberOfLeads')}`} {this.state.data.length ? this.state.data.length : this.genLang('downloading')}</div> */}
                                <div>{`${this.genLang('numberOfLeads')} ${this.state.data.length}`}</div>
                                
                                <NavItem className="ml-auto">
                                    {/* <Button 
                                        color={'primary'} outline 
                                        id='getLead'
                                        type={"button"}
                                        disabled={this.state.disabled === true}
                                        onClick={(e) => this.setState({disabled:true},this.getLead)}
                                    >
                                        {"Obtenir un Lead "}<FontAwesomeIcon icon={faPlusCircle} />
                                    </Button> */}
                                    {/* <UncontrolledTooltip placement="left" target='getLead'>
                                        Obtenir un Lead
                                    </UncontrolledTooltip> */}
                                </NavItem>
                                {/* </Nav> */}
                            </Nav>
                        </Col>
                    </Row>
                    <Table
                        genLang={this.genLang}
                        data={
                            _.map(this.state.data, (elt) => {
                                elt.thermoIcon = genThermoIcon(elt.thermo)
                                elt.alert = genAlertIcon(elt.priority)
                                elt.linkIcon = 
                                <Link 
                                    to={`/leads/${elt.id}`}
                                >
                                <IconButton
                                    icon={faExternalLink}
                                    tooltip={this.genLang('open')}
                                    style={{color: "#0076cf"}}
                                    onClick={() => {}}
                                />
                                </Link>
                                return elt;
                            })
                        }
                        columns={columns}
                    />
                </DashboardElement>
            {/* </Col>
        </Row></> */}
        </>
        )
    }
}

export default CenterSectionclose