import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import CenterSectionAdmin from "./component/CenterSectionAdmin";
import CenterSectionAdminSuivi from "./component/CenterSectionAdminsuivi";
import { genLang, getLang } from "../../../../composables/genLang";
import { Box } from "@mui/material";
import { Tab } from "@material-ui/core";
import { Tabs } from "@mui/material";
const LeadListAdmin = ({ trad }) => {
  const lang = getLang();

  const [tab, setTab] = useState(0);

  const tabOptions = [
    {
      label: "Leads",
      value: 0,
      component: (
        <CenterSectionAdmin
          trad={trad}
          lang={lang}
          tableView={tab === 0 ? "lead" : "rappel"}
        />
      ),
    },
    {
      label: "Suivis",
      value: 1,
      component: <CenterSectionAdminSuivi trad={trad} lang={lang} />,
    },
    {
      label: genLang({ en: "Reminders", fr: "Rappels" }),
      value: 2,
      component: (
        <CenterSectionAdmin
          trad={trad}
          lang={lang}
          tableView={tab === 0 ? "lead" : "rappel"}
        />
      ),
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          paddingTop: 0,
          position: "relative",
          marginBottom: "1rem",
        }}
      >
        <Tabs value={tab} onChange={(e, val) => setTab(val)}>
          {tabOptions.map((option, index) => (
            <Tab key={index} label={option.label} />
          ))}
        </Tabs>
      </Box>
      {tabOptions[tab].component}
    </Box>
  );
};

export default LeadListAdmin;
