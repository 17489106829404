import React from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { genLang } from "../../../../../composables/genLang";
const BACKEND1 = process.env.REACT_APP_API_URLX;
export default class AgentDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const date = new Date();
    const [str] = date.toISOString().split("T");
    axios
      .get(BACKEND1 + "/leads/stats", {
        params: {
          user: localStorage.getItem("user_id"),
          date: str,
        },
      })
      .then((res) => {
        const { data } = res.data;
        const realData = [
          {type: "qt", label: genLang("mySales"), value: data.qt, change: data.qt_diff},
          { type: "qt", label: genLang("myRecord"), value: data.qt_rec },
          {type: "amount", label: genLang("mySalesDollar"), value: Math.round(data.amount), change: data.amount_diff},
          {type: "amount", label: genLang("myRecordDollar"), value: Math.round(data.amount_rec)},
        ];
        this.setState({ data: realData });
      });
  }


  render() {
    const fakeData = [
      { label: genLang("mySales"), value: "57", change: 0 },
      { label: genLang("myRecord"), value: "24" },
      { type: "amount", label: genLang("mySalesDollar"), value: "501", change: "-10" },
      { type: "amount", label: genLang("myRecordDollar"), value: "24", change: "+3" },
    ];

    return (
      <div className="col-md-12">
        <div className="col-sm-12">
          <div className="element-wrapper" style={{ paddingBottom: "0rem" }}>
            <h6 className="element-header">{genLang("mySalesStats")}</h6>
            <div className="element-content">
              <div className="row">
                {this.state.data.map((i) => {
                  return (
                    <Col sm={3} xl={3} key={i.label}>
                      <a className="element-box el-tablo" href="#">
                        <div
                          className="label"
                          style={{ fontSize: "0.75rem", fontWeight: "600" }}
                        >
                          {i.label}
                        </div>
                        <div className="value">{i.value}</div>
                        {i.change && i.change !== 0 ? (
                          <div
                            className="trending"
                            style={
                              i.change > 0
                                ? { color: "green" }
                                : { color: "red" }
                            }
                          >
                            {i.change && i.change !== 0 && (
                              <span>{i.change} </span>
                            )}
                            {i.change !== 0 && <FontAwesomeIcon
                              icon={i.change > 0 ? faArrowUp : faArrowDown}
                              style={{ marginLeft: "0.3rem" }}
                            />}
                          </div>
                        ) : (
                          ""
                        )}
                      </a>
                    </Col>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
