import axios from "axios";

export const getSAASToken = (token) => {
  axios
    .get(`/v1/psj/login/${token}`, {
      baseURL: process.env.REACT_APP_SAAS_API_URL,
    })
    .then((res) => {
      const saasToken = res.data.token;
      localStorage.setItem("saas_token", saasToken);
    });
};

export const refreshSAASToken = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`/v1/psj/login/${token}`, {
    baseURL: process.env.REACT_APP_SAAS_API_URL,
  });
  localStorage.setItem("saas_token", data.token);
};
