import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PaymentIcon from "@material-ui/icons/Payment";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
//import BoardIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SpeedometerIcon from "@material-ui/icons/Speed";
import ShoppingIcon from "@material-ui/icons/Shop"; //ShoppingBasket
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import { checkIfUserIsAdmin } from "./actions/NeoSalesPlanningDailyAction";
import DollarIcon from "@material-ui/icons/AttachMoney";
import LinkIcon from "@material-ui/icons/Link";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { encryptText, decryptText } from "./appEncryption";
import axios from "axios";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCheck,
  faCopy,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { ClickAwayListener } from "@material-ui/core";
import { genLang } from "./composables/genLang";
const URL = process.env.REACT_APP_API_URLX;
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export function BaseLeftPanel2(props) {
  const { t, i18n } = useTranslation("common");
  const dispatch = useDispatch();

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [itemAdmin, setItemAdmin] = useState(0);
  const [profileMenus, setProfileMenus] = useState([]);
  const [showPRModal, setShowPRModal] = useState(false);
  const [referenceLink, setReferenceLink] = useState("");

  useEffect(() => {
    let profileMenusx = [];
    if (
      localStorage.getItem("profileperms") !== undefined &&
      localStorage.getItem("profileperms") !== null
    ) {
      profileMenusx = JSON.parse(
        decryptText(localStorage.getItem("profileperms"))
      );
      setProfileMenus(profileMenusx);
      // console.log(profileMenusx);
    }
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    props.handleLeftPanelMenuRefx(event);
  };

  function hasMacroAccess() {
    const menus = profileMenus.map((i) => i.page_name);
    if (menus.includes("GetMacrosListAction.php")) return true;
    return false;
  }

  function hasPriceAccess() {
    const menus = profileMenus.map((i) => i.page_name);
    // console.log(menus);
    if (menus.includes("EditProductAction.php")) return true;
    return false;
  }

  function genPartnerRefLink() {
    axios
      .post(
        URL + "/formpartnerpublic",
        {
          auth_id: localStorage.getItem("user_id"),
          data: [],
        },
        {
          params: {},
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("admin_token"),
            "x-jwt-token": localStorage.getItem("admin_token"),
          },
        }
      )
      .then((res) => {
        const link = res?.data?.data?.form_url;
        setReferenceLink(link);
        setShowPRModal(true);
      });
  }

  const clientObj = {
    firstname: "",
    lastname: "",
    email: "",
    id: "",
  };
  const calendarBaseURL =
    process.env.REACT_APP_ENV === "production"
      ? "https://neodoc.app"
      : "https://stg.neodoc.app";
  const url = axios.getUri({
    url: `/calendar/book/readonly`,
    params: {
      groups: [],
      domain_id: undefined, //product id
      service_id: 17, //step id
      clients: JSON.stringify([clientObj]),
      lang: localStorage.getItem("i18nextLng")?.split("-")[0] ?? "fr",
      external_user_id: "",
    },
  });

  const loginURL = axios.getUri({
    url: `${calendarBaseURL}/login-psj/${localStorage.getItem(
      "admin_token"
    )}`,
    params: {
      redirect: url,
    },
  });

  const list = (anchor) => (
    <div
      className={
        clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        }) + " scrollbar-visible"
      }
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ paddingBottom: "2rem" }}
    >
      <ul
        className="nav nav-pills nav-sidebar flex-column farxli"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li className="nav-item">
          <Link onClick={() => {window.open(loginURL)}}>
            <i className="nav-icon farx text-info">
              <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "0.2rem" }}/>
            </i>
            {genLang({en: "Calendar", fr: "Calendrier"})}
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/leads/closed">
            <i className="nav-icon farx text-info">
              <PaymentIcon />
            </i>
            {genLang({en: "My closed Leads", fr: "Mes leads fermés"})}
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/vwneoventes">
            <i className="nav-icon farx text-info">
              <PaymentIcon />
            </i>
            {t("zqwmenuel2_x")}
          </Link>
        </li>
        
        <li className="nav-item">
          <Link to="/vwneoventesupsell">
            <i className="nav-icon farx text-info">
              <PaymentIcon />
            </i>
            {t("zqwmenuel3_x")}
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/vwneoventesb2b">
            <i className="nav-icon farx text-info">
              <PaymentIcon />
            </i>
            {t("zqwmenuel5_x")}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/basicSWAPostSellactions">
            <i className="nav-icon farx text-info">
              <PaymentIcon />
            </i>
            {t("zqwCViewEl60_x")}
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/searchconflictlist">
            <i className="nav-icon farx text-info">
              <PaymentIcon />
            </i>
            {t("zqwNDICItxl2_x")}
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/prospectlist">
            <i className="nav-icon farx text-info">
              <AssignmentIcon />
            </i>
            {t("zqwNDICItxl3_x")}
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/sendsmscallfrm">
            <i className="nav-icon farx text-info">
              <AssignmentIcon />
            </i>
            SMS
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/merge-tickets">
            <i className="nav-icon farx text-info">
              <InsertDriveFileIcon />
            </i>
            Fusion des dossiers
          </Link>
        </li>

        {hasMenuAdminAccess(profileMenus, "GetNeoLeadsAction.php") === "3" ? (
          <li className="nav-item">
            <Link to="/leads/admin">
              <i className="nav-icon farx text-info">
                <PaymentIcon />
              </i>
              {t("zqwMesas019_x")}
            </Link>
          </li>
        ) : (
          ""
        )}
        {hasMenuAdminAccess(profileMenus, "GetNeoLeadsCatPersonAction.php") ===
        "3" ? (
          <li className="nav-item">
            <Link to="/users/admin">
              <i className="nav-icon farx text-info">
                <PaymentIcon />
              </i>
              {t("zqwMesas020_x")}
            </Link>
          </li>
        ) : (
          ""
        )}

        {hasMenuAdminAccess(profileMenus, "GetNeoLeadsAction.php") === "3" ? (
          <li className="nav-item">
            <Link to="/leads/adminsuivi">
              <i className="nav-icon farx text-info">
                <PaymentIcon />
              </i>
              {t("zqwMesas019_x")} Suivi
            </Link>
          </li>
        ) : (
          ""
        )}
        {hasMenuAdminAccess(
          profileMenus,
          "GetNeoSalesPlanningSellerAction.php"
        ) === "3" ? (
          <li className="nav-item">
            <Link to="/prospectreport">
              <i className="nav-icon farx text-info">
                <SpeedometerIcon />
              </i>
              Rapport Lead Prospect
            </Link>
          </li>
        ) : (
          ""
        )}
        {hasMenuAdminAccess(
          profileMenus,
          "GetNeoSalesPlanningSellerAction.php"
        ) === "3" ? (
          <li className="nav-item">
            <Link to="/allstatussales">
              <i className="nav-icon farx text-info">
                <ShoppingCartIcon />
              </i>
              {t("zqwmenuel6_x")}
            </Link>
          </li>
        ) : (
          ""
        )}
        {hasMenuAdminAccess(
          profileMenus,
          "GetNeoSalesPlanningSellerAction.php"
        ) === "3" ? (
          <li className="nav-item">
            <Link to="/salesplanningweekly">
              <i className="nav-icon farx text-info">
                <AssignmentIcon />
              </i>
              {t("zqwmenuel7_x")}
            </Link>
          </li>
        ) : (
          ""
        )}
        {hasMenuAdminAccess(
          profileMenus,
          "GetNeoSalesPlanningSellerAction.php"
        ) === "3" ? (
          <li className="nav-item">
            <Link to="/salesplanningpeople">
              <i className="nav-icon farx text-info">
                <ShoppingIcon />
              </i>
              {t("zqwmenuel8_x")}
            </Link>
          </li>
        ) : (
          ""
        )}
        {hasMenuAdminAccess(profileMenus, "GetMacrosListAction.php") === "3" ||
        hasMacroAccess() ? (
          <li className="nav-item">
            <Link to="/macros">
              <i className="nav-icon farx text-info">
                <EditIcon />
              </i>
              {"Macros"}
            </Link>
          </li>
        ) : (
          ""
        )}

        {hasMenuAdminAccess(profileMenus, "GetMacrosListAction.php") === "3" ||
        hasPriceAccess() ? (
          <li className="nav-item">
            <Link to="/prices">
              <i className="nav-icon farx text-info">
                <DollarIcon />
              </i>
              {"Prix"}
            </Link>
          </li>
        ) : (
          ""
        )}

        <li className="nav-item" onClick={genPartnerRefLink}>
          <Link>
            <i className="nav-icon farx text-info">
              <LinkIcon />
            </i>
            {"Référence du partenaire"}
          </Link>
        </li>
      </ul>
    </div>
  );

  if (props.openLeftMenu == true) {
    setTimeout(() => {
      setState({ ...state, ["left"]: true });
      props.handleLeftPanelMenuRefx(null);
    }, 1);
    setTimeout(() => {
      //here validate admin and show menu admin
      const tokadm = localStorage.tokadm;
      if (tokadm === undefined || tokadm === null) {
        Promise.resolve(dispatch(checkIfUserIsAdmin())).then((response) => {
          const cxz = response.NeoSalesPlanningDailyOne;
          //setSaleGoalDaily(cxz.qtyToday+"/"+cxz.expectation.expected_quantity);
          localStorage.setItem("tokadm", cxz.isadmin);
          setItemAdmin(cxz.isadmin);

          return response;
        });
      } else {
        setItemAdmin(tokadm);
      }
    }, 1);
  }

  const anchor = "left";
  return (
    <div className="MuiPaper-rootX">
      <ClickAwayListener onClickAway={toggleDrawer(anchor, false)}>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
          variant="persistent"
        >
          {list(anchor)}
          <LinkModal
            isOpen={showPRModal}
            toggle={() => setShowPRModal(false)}
            link={referenceLink}
          />
        </Drawer>
      </ClickAwayListener>
    </div>
  );
}

function hasMenuAdminAccess(profileMenus, page_name) {
  const token = localStorage.token;
  const tokenRid = localStorage.rid;
  let user = null;
  let userId = "-3";
  if (
    localStorage.getItem("user") !== undefined &&
    localStorage.getItem("user") !== null
  ) {
    user = decryptText(localStorage.getItem("user")).split(":");
    if (user !== null) {
      userId = user[4];
    }
  }

  if (profileMenus === null || profileMenus === undefined) {
    let profile = null;
    if (
      localStorage.getItem("profile") !== undefined &&
      localStorage.getItem("profile") !== null
    ) {
      profile = decryptText(localStorage.getItem("profile"));
    }
    if (
      localStorage.getItem("profileperms") !== undefined &&
      localStorage.getItem("profileperms") !== null
    ) {
      profileMenus = JSON.parse(
        decryptText(localStorage.getItem("profileperms"))
      );
    }
  }

  let accessMenu = "0";
  for (let menu of profileMenus) {
    if (
      (userId === "" + menu.user_id || "0" === "" + menu.user_id) &&
      "ADMIN" === "" + menu.perm_name &&
      ("sales" === "" + menu.app_name || "*" === "" + menu.app_name) &&
      (page_name === "" + menu.page_name || "*" === "" + menu.page_name)
    ) {
      accessMenu = "3";
      break;
    }
  }

  // {
  //   "id": "1",
  //   "user_id": "0",
  //   "page_name": "*",
  //   "perm_name": "INSERT",
  //   "department": "sales",
  //   "app_name": "sales",
  //   "observation": null
  // },

  return accessMenu;
}

function LinkModal({ isOpen, toggle, link }) {
  const [text, setText] = useState("Copie");
  const onCopyClick = () => {
    setText("Copié!");
    navigator.clipboard.writeText(link);
    setTimeout(() => {
      setText("Copie");
    }, 3000);
  };
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>Lien de référence</ModalHeader>
      <ModalBody>
        <Input value={link} disabled />
        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
          <Button
            style={{ height: "38px", marginTop: "0px" }}
            block
            color="primary"
            outline
            onClick={onCopyClick}
          >
            <FontAwesomeIcon
              style={{ marginRight: "0.5rem" }}
              icon={text === "Copie" ? faCopy : faCheck}
            />
            {text}
          </Button>
          <Button
            block
            style={{ height: "38px", marginTop: "0px" }}
            color="primary"
            onClick={() => window.open(link)}
          >
            <FontAwesomeIcon
              style={{ marginRight: "0.5rem" }}
              icon={faExternalLink}
            />
            {"Ouvrir"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
