import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addOrUpdateNeoVentes,
  getNeoVentes,
  getNeoVentesAll,
  getNeoVentesAllXStaus,
  getNeoVentesAllXStausx2,
  getNeoVentesGoto,
  deleteNeoVentes,
  getOrSetMultiActionStatus,
  getClientAndAddress,
  getClientPaymentsFullInfo,
  getGenAndCheckTicket,
} from "../../actions/NeoVentesAction";
import { withTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelectvente from "../CustomToolbarSelectventeconfp";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Toastr from "../../messages";
import NdiInterestConflict from "./NdiInterestConflict";
import {
  getSearchConflict,
  setSearchConflict,
  getNeoSearchByTicket,
} from "../../actions/NeoSearchAction";
import NdiFieldsMiniBoardzendesk from "../../srcndi/components/ndifields/NdiFieldsMiniBoardzendesk";
import NeoB2bleadProspectsForm from "../neob2bleadprospects/NeoB2bleadProspectsForm";
import SalesFlowForm from "./SalesFlowForm";
import NeoVentesForm from "./NeoVentesForm";
import { encryptText, decryptText } from "../../appEncryption";
import { CircularProgress } from "@mui/material";

class NeoVentesMySWABasicPostActions extends Component {
  actiongo = "";
  ticketListobj = [];
  interval = null;
  constructor(props) {
    super(props);
    this.state = {
      isLoaderOpen: false,
      toastrMessage: "",
      toastrType: "",
      toastrDuration: 6000,
      statusChargeDesk: 0,
      chargeDeskIdStr: "",
      invoiceId: 0,
      info_otheresp: "",
      openPossibleCDStatusInfo: false,
      possibleCDStatusInfo: {},
      possibleListOfCDStatusInfo: [],
      openNeoSearch: false,
      openNeoSearchlist: false,
      neoSearchList: [],
      client_id: "",
      ticket_id: "",
      neoemail: "",
      currentView: 1,
      openSaleflow: false,
      listOfTicketsPayments: {},
      productCart: [],
      idClient: "0",
      firstname: "",
      e_firstname: false,
      lastname: "",
      e_lastname: false,
      cell: "",
      e_cell: false,
      terms: "",
      e_terms: false,
      active: "",
      e_active: false,
      created: "",
      e_created: false,
      email: "",
      e_email: false,
      id_users: "0",
      e_id_users: false,
      language: "",
      e_language: false,
      push: "",
      e_push: false,
      id_zendesk: "",
      e_id_zendesk: false,
      sms: "1",
      e_sms: false,
      stripe_id: "",
      e_stripe_id: false,
      occupation: "",
      e_occupation: false,

      idAddress: 0,
      address: "",
      e_address: false,
      unitsuite: "",
      e_unitsuite: false,
      city: "",
      e_city: false,
      province: "QC",
      e_province: false,
      province2: "",
      cp: "",
      e_cp: false,
      country: "Canada",
      e_country: false,
      company: "",
      e_company: false,
      openB2bleadProspect: false,
      indexRowChecked: "",
      ticketlistall: "",
      transaction: "",
      openCheckingTicket: false,
      upsellof: "",
      errorTransacMessage: "",
      ticketListRec: {},
      openNeoSaleFormPopup: false,
      NeoVentes: { data: [] },
      fieldName: "",
      fieldVal: "",
    };
    this.deleteXRecordsMain = this.deleteXRecordsMain.bind(this);
    this.updateXRecordsMain = this.updateXRecordsMain.bind(this);
  }

  componentDidMount() {
    const { t } = this.props;
    this.setState({ ["isLoaderOpen"]: true });
    const this2 = this;
    const statusXObj = {
      statusList: "payed,paid-b2b",
      conflict: 2,
      specialSWA: 2,
      mySpecialSWA: 2,
    };
    Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
      .then((response) => {
        console.log("POSTACTION ", response);
        this.setState({
          ["isLoaderOpen"]: false,
          ["NeoVentes"]: response.NeoVentes,
        });
        this.showToast(t("zqwCViewEl11_x"), "success");
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        window.alert(error);
      });

    this.interval = setInterval(this.loadSilentlyPageRefresh, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadNextPage = (event) => {
    const { t } = this.props;
    const objBig = this.state.NeoVentes;
    if (objBig !== null && objBig.currentPage !== undefined) {
    } else {
      return false;
    }
    const currentPage = objBig.currentPage;
    const limit = objBig.limit;
    const maxpage = objBig.maxpage;
    const offset = objBig.offset;
    const records = objBig.records;
    const total = objBig.total;
    const fieldName = this.state.fieldName;
    const fieldVal = this.state.fieldVal;
    if (currentPage + 1 > maxpage) {
      return false;
    }

    const page = currentPage + 1;
    this.setState({ ["isLoaderOpen"]: true });
    const this2 = this;
    const statusXObj = {
      statusList: "payed,paid-b2b",
      conflict: 2,
      page: page,
      fieldName: fieldName,
      fieldVal: fieldVal,
      specialSWA: 2,
      mySpecialSWA: 2,
    };
    Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
      .then((response) => {
        const dataAll = this.state.NeoVentes;
        let data = {}; // dataAll.data; //this.state.NeoVentes.data;
        dataAll.data.push(...response.NeoVentes.data);
        dataAll.currentPage = parseInt(response.NeoVentes.currentPage);
        this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: dataAll });
        this.showToast(t("zqwCViewEl11_x"), "success");
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        window.alert(error);
      });
  };

  loadNextPageRedata = (event) => {
    const { t } = this.props;
    const fieldName = this.state.fieldName;
    const fieldVal = this.state.fieldVal;
    if (
      fieldName === null ||
      fieldName === "" ||
      fieldVal === null ||
      fieldVal === ""
    ) {
      this.showToast("Erreur Champs et Valeur/ Error Field and value", "error");
      return false;
    }

    this.setState({ ["isLoaderOpen"]: true });
    const this2 = this;
    const statusXObj = {
      statusList: "payed,paid-b2b",
      conflict: 2,
      page: 1,
      fieldName: fieldName,
      fieldVal: fieldVal,
      specialSWA: 2,
      mySpecialSWA: 2,
    };
    Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
      .then((response) => {
        this.setState({
          ["isLoaderOpen"]: false,
          ["NeoVentes"]: response.NeoVentes,
        });
        this.showToast(t("zqwCViewEl11_x"), "success");
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        window.alert(error);
      });
  };

  loadNextPageReset = (event) => {
    const { t } = this.props;
    const fieldName = this.state.fieldName;
    const fieldVal = this.state.fieldVal;
    this.setState({
      ["isLoaderOpen"]: true,
      ["fieldName"]: "",
      ["fieldVal"]: "",
    });
    const this2 = this;
    const statusXObj = {
      statusList: "payed,paid-b2b",
      conflict: 2,
      page: 1,
      fieldName: "",
      fieldVal: "",
      specialSWA: 2,
      mySpecialSWA: 2,
    };
    Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
      .then((response) => {
        this.setState({
          ["isLoaderOpen"]: false,
          ["NeoVentes"]: response.NeoVentes,
        });
        this.showToast(t("zqwCViewEl11_x"), "success");
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        window.alert(error);
      });
  };

  loadSilentlyPageRefresh = (event) => {
    const { t } = this.props;
    const fieldName = this.state.fieldName;
    const fieldVal = this.state.fieldVal;
    const indexRowChecked = this.state.indexRowChecked;
    if (indexRowChecked === "" && fieldName === "" && fieldVal === "") {
    } else {
      console.log(
        "222-POSTACTION loadSilentlyPageRefresh gooooooo, we block it for ind ",
        indexRowChecked
      );
      return false;
    }

    this.setState({ ["fieldName"]: "", ["fieldVal"]: "" });
    const this2 = this;
    const statusXObj = {
      statusList: "payed,paid-b2b",
      conflict: 2,
      page: 1,
      fieldName: "",
      fieldVal: "",
      specialSWA: 2,
      mySpecialSWA: 2,
    };
    Promise.resolve(this.props.getNeoVentesAllXStausx2(statusXObj))
      .then((response) => {
        this.setState({ ["NeoVentes"]: response.NeoVentes });
        return response;
      })
      .catch(function (error) {
        console.log("loadSilentlyPageRefresh dataAll ", error);
      });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFocusout = (event) => {};

  setTicketlistChanged = (event) => {
    //const trans=event.currentTarget.dataset.transaction;
    const trans =
      event.target[event.target.selectedIndex].getAttribute("data-transaction");
    this.setState({
      [event.target.name]: event.target.value,
      ["transaction"]: "" + trans,
    });
    console.log("hello tr ", trans);
  };

  //name="ticketlistall" id="ticketlistall" onChange={this.setTicketlistChanged}

  showToast = (arg, toastType) => {
    //toastType: success or error or warning or info
    const toastrDuration = this.state.toastrDuration; //5500
    this.setState({
      toastrMessage: arg,
      toastrType: toastType,
    });
    setTimeout(() => {
      this.setState({
        toastrMessage: "",
        toastrType: "success",
      });
    }, toastrDuration);
  };

  deleteXRecordsMain(selectedRows) {
    const objList = this.state.NeoVentes.data;
    let gridIndexes = [];
    let gridIds = [];
    selectedRows.data.map((row) => {
      gridIndexes.push(row.index);
      return null;
    });

    gridIndexes.reverse();
    for (const index of gridIndexes) {
      const obj = objList[index];
      gridIds.push(obj.id);
      objList.splice(index, 1);
    }
    this.props.deleteNeoVentes(gridIds[0]);
  }

  updateXRecordsMain(selectedRows, indexName) {
    const { t } = this.props;
    const objList = this.state.NeoVentes.data;
    let gridIndexes = [];
    let idIndex = -1;
    selectedRows.data.map((row) => {
      gridIndexes.push(row.dataIndex); //global
      idIndex = row.dataIndex;
      return null;
    });

    console.log(
      "Teeeeeeeeeeeeeeeeeeeeeeest " + idIndex + "   indexName=" + indexName,
      selectedRows
    );

    if (gridIndexes.length > 1) {
      alert(t("zqwCViewEl12_x")); //alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }

    let obj = objList[idIndex];
    const invoiceId = obj.id;
    this.actiongo = indexName;
    const client_id = obj.id_clients;
    let ticket_id = obj.ticket_id;
    const objAction = { invoiceId: obj.id, actiongo: indexName };
    const email = obj.email;
    const indexRowCheckedx = this.state.indexRowChecked;
    const ticketlistallx = this.state.ticketlistall;
    const transactionx = this.state.transaction;
    if (indexRowCheckedx !== null && indexRowCheckedx !== "") {
      if (ticketlistallx !== null && ticketlistallx !== "") {
        ticket_id = ticketlistallx;
      }
    }

    const this2 = this;
    if (indexName === "mkconflict") {
      console.log("ooooooooooopen search conflict");
      this.setState({
        ["client_id"]: client_id,
        ["ticket_id"]: ticket_id,
        ["openNeoSearch"]: true,
        ["currentView"]: 1,
      });

      return false;
    } else if (indexName === "ndi") {
      console.log("ooooooooooopen NDI neooooooooolegal");
      this.setState({
        ["client_id"]: client_id,
        ["ticket_id"]: ticket_id,
        ["openNeoSearch"]: false,
        ["currentView"]: 2,
        ["neoemail"]: email,
      });

      return false;
    } else if (indexName === "rendezvous") {
      const serachObj = { clientId: client_id, ticketId: ticket_id };
      Promise.resolve(this.props.getClientPaymentsFullInfo(serachObj))
        .then((response) => {
          console.log("getClientTransactionList  ");
          console.log(response);
          this.setState({ ["listOfTicketsPayments"]: response.NeoVentesOne });
          return response;
        })
        .catch(function (error) {
          const errObj = error;
          let errMessage =
            errObj.data !== ""
              ? "Erreur " + " \n" + errObj.data
              : "Erreur " + " \n" + errObj.statusText;
          if (errObj.status === undefined) {
            errMessage +=
              "\n There was some error from the expected data from the server. Please try again later.";
          }
          console.log(error);
        });

      Promise.resolve(this.props.getClientAndAddress(client_id)).then(
        (response) => {
          const obj = response.NeoAddressOne;
          const newValue = obj.client;
          const address = obj.address;
          const clientInfo = {
            id_clients: newValue.id,
            idClient: newValue.id,
            firstname: newValue.firstname,
            lastname: newValue.lastname,
            cell: newValue.cell,
            terms: newValue.terms,
            active: newValue.active,
            email: newValue.email,
            id_users: parseInt(newValue.id_users),
            language: newValue.language,
            push: newValue.push,
            id_zendesk: newValue.id_zendesk,
            sms: newValue.sms,
            stripe_id: newValue.stripe_id,
            occupation: newValue.occupation,
          };
          const clientAddress = {
            idAddress: address.id,
            address: address.address,
            city: address.city,
            province: address.province,
            cp: address.cp,
            country: address.country,
            company: address.company,
          };
          this.setState(clientInfo); //xaza  this.setState({ clientInfo });
          this.setState(clientAddress); //this.setState({ clientAddress });

          return response;
        }
      );

      this.setState({
        ["client_id"]: client_id,
        ["ticket_id"]: ticket_id,
        ["openNeoSearch"]: false,
        ["currentView"]: 3,
        ["neoemail"]: email,
      });

      return false;
    } else if (indexName === "saleflow") {
      this.setState({
        ["client_id"]: client_id,
        ["ticket_id"]: ticket_id,
        ["currentView"]: 1,
        ["invoiceId"]: invoiceId,
        ["openSaleflow"]: true,
      });

      return false;
    } else if (indexName === "lead2Action") {
      this.setState({
        ["client_id"]: client_id,
        ["ticket_id"]: ticket_id,
        ["currentView"]: 1,
        ["invoiceId"]: invoiceId,
        ["openB2bleadProspect"]: true,
      });

      return false;
    }

    this.setState({ ["isLoaderOpen"]: true });
    Promise.resolve(this.props.getNeoSearchByTicket(ticket_id))
      .then((response) => {
        if (indexName === "conflict") {
          const infox = response.NeoSearch;
          this.setState({
            ["isLoaderOpen"]: false,
            ["openNeoSearchlist"]: true,
            ["neoSearchList"]: infox,
          });
        } else {
          const info = response.NeoVentesOne; //JSON.parse(response); // response.NeoVentesOne;
          this.setState({
            ["isLoaderOpen"]: false,
            ["chargeDeskIdStr"]: info.messageAction,
            ["openPossibleCDStatusInfo"]: true,
            ["invoiceId"]: invoiceId,
            ["info_otheresp"]: info.otheresp,
          });
        }
        this.showToast(t("zqwCViewEl11_x"), "success"); //chargeDeskIdStr
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        alert(error);
      });
  }

  checkMissingTickets = (event) => {
    const indexRowChecked = this.state.indexRowChecked;
    const ticketlistallx = this.state.ticketlistall;
    const saleId = this.state.ticketListRec["id"];
    const this00 = this;
    const client_id = this.state.ticketListRec["id_clients"];
    const confIndex = this.state.confIndex;

    if (confIndex === indexRowChecked) {
      this.setState({ ["openCheckingTicket"]: true });
      return false;
    }
    if (parseInt(saleId) > 0) {
    } else {
      this.showToast("Cochez une facture", "error"); //t("zqwCViewEl11_x")
      return false;
    }

    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });

    const serachObj = { clientId: client_id, ticketId: 0, saleId: saleId };
    Promise.resolve(this.props.getClientPaymentsFullInfo(serachObj))
      .then((response) => {
        this.setState({
          ["listOfTicketsPayments"]: response.NeoVentesOne,
          ["confIndex"]: indexRowChecked,
        });
        this.setState({
          ["isLoaderOpen"]: false,
          ["openCheckingTicket"]: true,
        });
        return response;
      })
      .catch(function (error) {
        const errObj = error;
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage +=
            "\n There was some error from the expected data from the server. Please try again later.";
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });
        console.log(error);
      });
  };

  getGenAndCheckTicket = (event) => {
    const indexRowChecked = this.state.indexRowChecked;
    const ticketlistallx = this.state.ticketlistall;
    const saleId = this.state.ticketListRec["id"];
    const this00 = this;
    const confIndex = this.state.confIndex;
    const transId = event.currentTarget.dataset.transaction;
    const isupsell = event.currentTarget.dataset.isupsell;
    const upsellof = this.state.upsellof;
    this.setState({ ["isLoaderOpen"]: true, ["errorTransacMessage"]: "" });

    const serachObj = {
      transactionId: transId,
      ticketId: 0,
      invoiceId: saleId,
      genTicket: "yes",
      upsellof: upsellof,
    };
    Promise.resolve(this.props.getGenAndCheckTicket(serachObj))
      .then((response) => {
        this.setState({
          ["ticketListRec"]: response.NeoVentesOne.saleticketList,
          ["isLoaderOpen"]: false,
        }); //["openCheckingTicket"]:true,
        return response;
      })
      .catch(function (error) {
        const errObj = error;
        //let errMessage=(errObj.data!=="")? "Error "+errObj.status+" "+errObj.statusText+" \n"+errObj.data:"Error "+errObj.status+" "+errObj.statusText;
        let errMessage =
          errObj.data !== ""
            ? "Erreur " + " \n" + errObj.data
            : "Erreur " + " \n" + errObj.statusText;
        if (errObj.status === undefined) {
          errMessage +=
            "\n There was some error from the expected data from the server. Please try again later.";
        }
        this00.setState({
          ["isLoaderOpen"]: false,
          ["errorTransacMessage"]: errMessage,
        });

        console.log("ERROR WITH TRANSACTION");
        console.log(error);
        console.log("SHORT ERROR MESSAGE: " + errMessage);
        console.log("RWA RESPONSE");
      });

    //return false;
  };

  openTicketSale = (event) => {
    const ticketID = this.state.ticketlistall;
    const saleId = this.state.ticketListRec["id"];
    if (parseInt(ticketID) > 0 && parseInt(saleId) > 0) {
    } else {
      this.showToast("Cochez et choisir un Ticket & facture", "error"); //t("zqwCViewEl11_x")
      return false;
    }
    this.setState({ ["openNeoSaleFormPopup"]: true });
  };

  setToReturn = () => {
    this.setState({ ["openNeoSearch"]: false, ["currentView"]: 1 });

    return false;
  };

  updateXRecordsMain000(selectedRows, indexName) {
    const { t } = this.props;
    const objList = this.state.NeoVentes.data;
    let gridIndexes = [];
    let idIndex = -1;
    selectedRows.data.map((row) => {
      gridIndexes.push(row.dataIndex); //global
      idIndex = row.dataIndex;
      return null;
    });

    console.log(
      "Teeeeeeeeeeeeeeeeeeeeeeest " + idIndex + "   indexName=" + indexName,
      selectedRows
    );

    if (gridIndexes.length > 1) {
      alert(t("zqwCViewEl12_x")); //alert(`{t('zqwgridIndexesfound_x')}`);
      return false;
    }

    let obj = objList[idIndex];
    const invoiceId = obj.id;
    //this.props.getNeoVentesGoto(obj.id);
    console.log(
      "oooooooooooooooooooooooh " + obj.id + "   indexName=" + indexName
    );
    //history.push(`./neoventes/${obj.id}/edit`);
    this.actiongo = indexName;
    const objAction = { invoiceId: obj.id, actiongo: indexName };
    this.setState({ ["isLoaderOpen"]: true });
    const this2 = this;
    Promise.resolve(this.props.getOrSetMultiActionStatus(objAction))
      .then((response) => {
        const info = response.NeoVentesOne; //JSON.parse(response); // response.NeoVentesOne;
        this.setState({
          ["isLoaderOpen"]: false,
          ["chargeDeskIdStr"]: info.messageAction,
          ["openPossibleCDStatusInfo"]: true,
          ["invoiceId"]: invoiceId,
          ["info_otheresp"]: info.otheresp,
        });
        this.showToast(t("zqwCViewEl11_x"), "success"); //chargeDeskIdStr
        return response;
      })
      .catch(function (error) {
        this2.setState({ ["isLoaderOpen"]: false });
        alert(error);
      });
  }

  render() {
    const { t } = this.props;
    const NeoSearchResultHistory = this.NeoSearchResultHistory;
    const NeoSearchActions = this.NeoSearchActions;
    const NeoSalesFlowForm = this.NeoSalesFlowForm;
    const NeoB2bleadProspectsFormx = this.NeoB2bleadProspectsFormx;
    const NeoSalesCheckingTicketsForm = this.NeoSalesCheckingTicketsForm;
    const NeoSaleFormPopup = this.NeoSaleFormPopup;
    const backTicketId = this.state.ticket_id; //backTicketId;
    const neoemail = this.state.neoemail;
    const currentView = this.state.currentView;
    const indexRowChecked = this.state.indexRowChecked;

    const columns = [
      {
        name: "id",
        label: t("zqwid_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "fullName",
        label: t("zqwid_clients_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "status",
        label: t("zqwstatus_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "due_date",
        label: t("zqwdue_date_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "vendor",
        label: t("zqwCViewEl14_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "total",
        label: t("zqwtotal_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "codeName",
        label: "Transaction",
        options: { filter: true, sort: true },
      },
      {
        name: "province",
        label: t("zqwprovince_x"),
        options: { filter: true, sort: true },
      },
      {
        name: "ticket_id",
        label: "Ticket",
        options: { filter: true, sort: true },
      },
      {
        name: "searchconflict",
        label: "Qté/Qty", //t('zqwNDICItx2x3_x'),
        options: { filter: true, sort: true },
      },
    ];

    const textLabelOptions = {
      body: {
        noMatch: t("zqwgridnoMatch_x"),
        toolTip: t("zqwgridtoolTiph_x"),
        columnHeaderTooltip: (column) =>
          `${t("zqwgridSortfor_x")} ${column.label}`,
      },
      pagination: {
        next: t("zqwgridnext_x"),
        previous: t("zqwgridprevious_x"),
        rowsPerPage: t("zqwgridrowsPerPage_x"),
        displayRows: t("zqwgriddisplayRows_x"),
      },
      toolbar: {
        search: t("zqwgridsearch_x"),
        downloadCsv: t("zqwgriddownloadCsv_x"),
        print: t("zqwgridprint_x"),
        viewColumns: t("zqwgridviewColumns_x"),
        filterTable: t("zqwgridfilterTable_x"),
      },
      filter: {
        all: t("zqwgridall_x"),
        title: t("zqwgridftitle_x"),
        reset: t("zqwgridreset_x"),
      },
      viewColumns: {
        title: t("zqwgridvctitle_x"),
        titleAria: t("zqwgridvctitleAria_x"),
      },
    };

    const options = {
      filterType: "checkbox",
      textLabels: textLabelOptions,
      onRowsSelect: (currentRowsSelected, allRowsSelected) => {
        if (allRowsSelected.length == 1) {
          this.setState({
            ["indexRowChecked"]: "" + allRowsSelected[0]["dataIndex"],
          });

          const objListSales = this.state.NeoVentes.data;
          let obj = objListSales[allRowsSelected[0]["dataIndex"]];
          this.ticketListobj = obj;
          this.setState({ ["ticketListRec"]: obj, ["ticketlistall"]: "" });
          if (obj.ticketList.length <= 1 && parseInt(obj.ticket_id) > 0) {
            this.setState({ ["ticketlistall"]: obj.ticket_id });
          }
        } else {
          this.setState({
            ["indexRowChecked"]: "",
            ["ticketListRec"]: {},
            ["ticketlistall"]: "",
          });
        }
      },
      onRowClick: (rowData, rowState) => {
        // console.log(rowData, rowState);
      },
      textLabels: {
        body: {
          noMatch: this.state.isLoaderOpen ? (
            <CircularProgress />
          ) : (
            "Sorry, there is no matching data to display"
          ),
        },
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelectvente
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          deleteXRecords={this.deleteXRecordsMain}
          updateXRecords={this.updateXRecordsMain}
          isEdit={true}
          isDelete={true}
          t={this.props.t}
        />
      ),
    };

    let ticketList = []; //let ticketListobj=[];
    const objListSales = this.state.ticketListRec;
    if (indexRowChecked !== "") {
      let obj = objListSales;
      console.log("**************ticketList****", obj);
      if (
        obj !== undefined &&
        obj !== null &&
        obj.ticketList !== undefined &&
        obj.ticketList.length > 0
      ) {
        let ky = 0;
        for (let ticketobj of obj.ticketList) {
          ticketList.push(
            <option
              key={"or" + ky}
              data-transaction={"" + ticketobj.tr}
              value={"" + ticketobj.ta}
            >
              {"TA " + ticketobj.ta + " " + ticketobj.description}
            </option>
          );
          ky++;
        }
      }

      if (
        obj !== undefined &&
        obj !== null &&
        obj.ticketList !== undefined &&
        obj.ticketList.length < 1
      ) {
        if (obj.upsale_ticket === null && obj.ticket_id === null) {
        }
      }
    }

    const Alert = this.Alert;
    const ticketlistallx = this.state.ticketlistall;
    const myToastMessage = this.state.toastrMessage;
    const toastrType = this.state.toastrType;
    const CheckOrSetActionsOnInvoice = this.CheckOrSetActionsOnInvoice;

    if (currentView === 1) {
      return (
        <div>
          <h4>{t("zqwCViewEl62_x")}</h4>
          <select
            name="ticketlistall"
            id="ticketlistall"
            onChange={this.setTicketlistChanged}
          >
            <option value="">Choisir/Select Ticket</option>
            {ticketList}
          </select>{" "}
          &nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.openTicketSale}
          >
            OpenTicketSale
          </button>{" "}
          &nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.loadNextPage}
          >
            LoadNextPage
          </button>{" "}
          &nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.loadNextPageReset}
          >
            Rafraichir/Refresh
          </button>
          <div
            style={{
              marginTop: "5px",
              marginBottom: "5px",
              padding: "5px",
            }}
          >
            <span>Filtrer serveur/Server filtering</span> &nbsp;
            <select
              name="fieldName"
              id="fieldName"
              onChange={this.handleChange}
              value={this.state.fieldName}
            >
              <option value="">Choisir/Select Champs/Field</option>
              <option value="invId">#Facture/Invoice</option>
              <option value="invCliId">#Client/Clint#</option>
              <option value="invTicket">#Ticket/Ticket#</option>
              <option value="invEmail">Email</option>
              <option value="invPhone">#Telephone/Phone#</option>
            </select>{" "}
            &nbsp;
            <input
              name="fieldVal"
              id="fieldVal"
              onChange={this.handleChange}
              value={this.state.fieldVal}
            />
            &nbsp;
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.loadNextPageRedata}
            >
              Charger/Load
            </button>
          </div>
          {ticketlistallx !== null &&
          ticketlistallx !== "" &&
          indexRowChecked !== null &&
          indexRowChecked !== "" ? (
            <>
              <br />
              <Alert severity="success">
                Ticket: #{ticketlistallx} &nbsp;
                <span style={{ right: "20px", color: "#FFFFFF" }}>
                  <a
                    href={
                      "https://crm2.neolegal.ca/zendesk/" +
                      ticketlistallx +
                      "/ticket"
                    }
                    className="btn btn-warning"
                    target="_blank"
                  >
                    {t("zqwCViewEl59_x")} #{ticketlistallx}
                  </a>
                </span>
              </Alert>
            </>
          ) : (
            ""
          )}
          <MUIDataTable
            title={""}
            data={this.state.NeoVentes.data}
            columns={columns}
            options={options}
          />
          <Toastr
            messagex={myToastMessage}
            open={true}
            toastType={toastrType}
          />
          <CheckOrSetActionsOnInvoice openx={this.state.isLoaderOpen} />
          <NeoSearchResultHistory />
          <NeoSearchActions />
          <NeoSalesFlowForm />
          <NeoB2bleadProspectsFormx />
          <NeoSalesCheckingTicketsForm />
          <NeoSaleFormPopup />
        </div>
      );
    } else if (currentView === 2) {
      return (
        <div>
          <h4>{t("zqwCViewEl55_x")}</h4>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.setToReturn}
          >
            {t("zqwCViewEl56_x")}
          </button>
          <NdiFieldsMiniBoardzendesk
            backTicketId={backTicketId}
            neoemail={neoemail}
          />
        </div>
      );
    } else if (currentView === 3) {
      const listTiPays = this.state.listOfTicketsPayments;
      let transactions = [];
      if (
        listTiPays !== null &&
        listTiPays.transactions !== null &&
        listTiPays.transactions !== undefined
      ) {
        transactions = listTiPays.transactions;
      }

      const firstname = this.state.firstname;
      const lastname = this.state.lastname;
      const cell = this.state.cell;
      const email = this.state.email;
      const gender = ""; //this.state.gender;
      const language = this.state.language;
      const dob = ""; //this.state.dob;
      const source = ""; //this.state.source;
      const current_insurances = "";
      const id_invoices = this.state.id_invoice;
      const address = this.state.address;
      const unitsuite = this.state.unitsuite;
      const city = this.state.city;
      const province = this.state.province;
      const cp = this.state.cp;
      const country = this.state.country;

      const ticket_id = this.state.ticket_id;
      const client_id = this.state.idClient; // client_id;

      const backTicketId = this.state.ticket_id; //433332;
      const invoiceId = this.state.id_invoice; //4750;
      const neoemail = this.state.email; //"sidbenac_zx23@gmail.com";  //client_email
      const client_email = this.state.email; //"sidbenac@gmail.com";
      const neoClientId = this.state.id_zendesk; //413958800713;
      // 15;
      // import { encryptText, decryptText } from './appEncryption';
      let profile = null;
      let id_zendeskProf = "";
      if (
        localStorage.getItem("profile") !== undefined &&
        localStorage.getItem("profile") !== null
      ) {
        // profile=(decryptText(localStorage.getItem("profile"))).split(':');
        profile = decryptText(localStorage.getItem("profile"));
        const rProfile = JSON.parse(profile);
        if (rProfile.id_zendesk !== undefined && rProfile.id_zendesk !== null) {
          id_zendeskProf = rProfile.id_zendesk;
        }
      }

      let subjectOne = "";

      transactions.length > 0 &&
        transactions.map((item, i) => {
          if (item.description !== "" && subjectOne === "") {
            subjectOne = "" + item.description;
          }
        }, this);

      const id_zendeskAgent = id_zendeskProf; //26141155848;
      const client_name = firstname + " " + lastname; //"John 007 Doe";
      const subject = subjectOne; //"Envoyez une mise en demeure avec un avocat";
      const details =
        client_name +
        " \n" +
        address +
        ", " +
        city +
        "(" +
        province +
        ") " +
        cp +
        " " +
        country;

      return (
        <div>
          <h4>{t("zqwNDICItbook2_x")}</h4>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.setToReturn}
          >
            {t("zqwCViewEl56_x")}
          </button>
          <div>Consulter l'equipe IT</div>
        </div>
      );
    }
  }

  CheckOrSetActionsOnInvoice = (event) => {
    const open = this.state.openPossibleCDStatusInfo; //lg sm md  fullWidth={"sm"} maxWidth={"lg"}
    const { t } = this.props;
    //const { t, i18n } = useTranslation("common");
    const listObj = this.state.possibleListOfCDStatusInfo;
    const info_otheresp = this.state.info_otheresp;
    let maxWidth = "sm"; //"sm";
    let isfullScreen = false;
    if (
      info_otheresp !== null &&
      info_otheresp !== "" &&
      this.actiongo === "viewemailinvoice"
    ) {
      maxWidth = "lg";
      isfullScreen = true;
    }

    return (
      <div>
        <Dialog
          maxWidth={maxWidth}
          open={open}
          onClose={this.handleCloseListOfCDStatusInfo}
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen={isfullScreen}
          aria-labelledby="form-dialog-confirmclient4"
        >
          <DialogContent id="form-dialog-confirmclient4">
            <div className="text-center">
              <h2>{t("zqwCViewEl16_x")}</h2>
            </div>
            <p>
              {t("zqwCViewEl17_x")} # {this.state.invoiceId}{" "}
            </p>
            <p> &nbsp; </p>

            {info_otheresp !== null &&
            info_otheresp !== "" &&
            this.actiongo === "viewemailinvoice" ? (
              <div className="form-group col-11">
                <iframe
                  srcDoc={info_otheresp}
                  sandbox="allow-same-origin"
                  allowFullScreen={true}
                  style={{
                    minHeight: "400px",
                    minWidth: "700px",
                    border: "none",
                  }}
                  name="iframe_emac"
                  title="Email Content"
                ></iframe>
                <input
                  type="button"
                  onClick={this.handleCloseListOfCDStatusInfo}
                  className="btn btn-success float-right"
                  value={t("zqwNVFrmbtn_x")}
                />
              </div>
            ) : (
              <p>{this.state.chargeDeskIdStr} </p>
            )}

            <div className="form-row neoxtbl">
              <div className="form-group col-md-9">
                <br />
                <input
                  type="button"
                  onClick={this.handleCloseListOfCDStatusInfo}
                  className="btn btn-default"
                  value={t("zqwNVFrmbtn_x")}
                />{" "}
                &nbsp; &nbsp;
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  handleCloseListOfCDStatusInfo = (anyx) => {
    this.setState({ ["openPossibleCDStatusInfo"]: false });
  };

  //neoSearchData
  handleCloseNeoSearch = (anyEvent) => {
    console.log("OLD STYLE SUCCESS handleCloseNeoSearch ");
    this.setState({
      ["openNeoSearch"]: false,
      ["openNeoSearchlist"]: false,
      ["openSaleflow"]: false,
      ["openB2bleadProspect"]: false,
      ["openCheckingTicket"]: false,
      ["openNeoSaleFormPopup"]: false,
    });
  };

  handleOpenNeoSearch = (anyEvent) => {
    this.setState({ ["openNeoSearch"]: true });
    console.log("OLD STYLE SUCCESS handleOpenNeoSearch ");
  };

  Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  NeoSearchResultHistory = (props) => {
    const { t } = this.props;
    const myLang = t("curlang");
    const openNeoSearch = this.state.openNeoSearchlist;
    if (!openNeoSearch) {
      return null;
    }

    const neoSearch = this.state.neoSearchList;
    if (neoSearch !== undefined && neoSearch !== null && neoSearch.length > 0) {
    } else {
      return null;
    }

    console.log("neoSearchneoSearchneoSearchneoSearch ", neoSearch);

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={openNeoSearch}
          onClose={this.handleCloseNeoSearch}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm">
            <NdiInterestConflict neoSearchData={neoSearch} />

            <div className="form-group col-md-9">
              <br />
              <input
                type="button"
                onClick={this.handleCloseNeoSearch}
                className="btn btn-default"
                value={t("Close_x")}
              />{" "}
              &nbsp; &nbsp;
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  NeoSearchActions = (props) => {
    const { t } = this.props;
    const myLang = t("curlang");
    const openNeoSearch = this.state.openNeoSearch;
    if (!openNeoSearch) {
      return null;
    }

    const ticket_id = this.state.ticket_id;
    const client_id = this.state.client_id;
    if (
      ticket_id !== null &&
      ticket_id !== "" &&
      client_id !== null &&
      client_id !== ""
    ) {
    } else {
      console.log(" nothing to show ", ticket_id, client_id);
      return null;
    }

    return (
      <div>
        <Dialog
          maxWidth={"sm"}
          open={openNeoSearch}
          onClose={this.handleCloseNeoSearch}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm">
            <NdiInterestConflict
              ticket_id={"" + ticket_id}
              client_id={"" + client_id}
            />

            <div className="form-group col-md-9">
              <br />
              <input
                type="button"
                onClick={this.handleCloseNeoSearch}
                className="btn btn-default"
                value={t("Close_x")}
              />{" "}
              &nbsp; &nbsp;
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  NeoSalesFlowForm = (props) => {
    const { t } = this.props;
    const myLang = t("curlang");
    const open = this.state.openSaleflow;
    if (!open) {
      return null;
    }

    const client_id = this.state.client_id;
    const ticket_id = this.state.ticket_id;
    const invoiceId = this.state.invoiceId;

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseNeoSearch}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm">
            <SalesFlowForm
              client_id={client_id}
              ticket_id={ticket_id}
              invoiceId={invoiceId}
            />

            <div className="form-group col-md-9">
              <br />
              <input
                type="button"
                onClick={this.handleCloseNeoSearch}
                className="btn btn-default"
                value={t("Close_x")}
              />{" "}
              &nbsp; &nbsp;
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  NeoB2bleadProspectsFormx = (props) => {
    const { t } = this.props;
    const myLang = t("curlang");
    const open = this.state.openB2bleadProspect;
    if (!open) {
      return null;
    }

    const client_id = this.state.client_id;
    const ticket_id = this.state.ticket_id;
    const invoiceId = this.state.invoiceId;
    const frminput = { rndidpros: undefined };

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseNeoSearch}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confProspect">
            <NeoB2bleadProspectsForm
              frminput={frminput}
              frminputClose={null}
              frminputGetDataFrmSrv={777}
              client_id={client_id}
              ticket_id={ticket_id}
              invoiceId={invoiceId}
            />
            <div className="form-group col-md-9">
              <br />
              <input
                type="button"
                onClick={this.handleCloseNeoSearch}
                className="btn btn-default"
                value={t("Close_x")}
              />{" "}
              &nbsp; &nbsp;
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  NeoSalesCheckingTicketsForm = (props) => {
    const { t } = this.props;
    const myLang = t("curlang");
    const open = this.state.openCheckingTicket;
    if (!open) {
      return null;
    }

    const listOfTicketsPayments = this.state.listOfTicketsPayments;

    const client_id = this.state.client_id;
    const ticket_id = this.state.ticket_id;
    const invoiceId = this.state.invoiceId;
    const errorMessage = this.state.errorTransacMessage;
    const Alert = this.Alert;

    console.log(
      "NeoSalesCheckingTicketsForm ",
      invoiceId,
      client_id,
      ticket_id
    );

    let ky = 0;
    let transactionsTr = [];
    let ticketListTrans = [];
    const transactions =
      this.state.listOfTicketsPayments.gohistory.transactions;
    const objListSales = this.state.ticketListRec;

    for (let trans of transactions) {
      console.log(
        "NeoSalesCheckingTicketsForm sub4 ",
        trans.id_invoices,
        this.ticketListobj.id
      );
      if (trans.id_invoices !== this.ticketListobj.id) {
        continue;
      }
      let ticketRef = "";

      const idTr = parseInt(trans.id_products);
      if (idTr < 9999) {
        for (let tlinf of objListSales.ticketList) {
          if (trans.id === tlinf.tr) {
            ticketRef = " (TA " + tlinf.ta + ")";
            break;
          }
        }
        ticketListTrans.push(
          <option key={"tptor" + ky} value={"" + trans.id}>
            {" " + trans.id + ticketRef}{" "}
          </option>
        );
      }
      transactionsTr.push(
        <tr key={"trtr" + ky}>
          <td>{trans.id}</td>
          <td>{trans.description}</td>
          <td>
            {idTr > 9999 ? (
              <button
                type="button"
                className="btn btn-primary"
                data-transaction={trans.id}
                onClick={this.getGenAndCheckTicket}
              >
                upsell
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                data-transaction={trans.id}
                onClick={this.getGenAndCheckTicket}
              >
                GenTicket
              </button>
            )}
          </td>
        </tr>
      );
      ky++;
    }

    return (
      <div>
        <Dialog
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseNeoSearch}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-confirm"
        >
          <DialogContent id="form-dialog-confirm">
            <h4>Facture/Invoice: #{this.ticketListobj.id}</h4>
            Upsell de
            <select name="upsellof" id="upsellof" onChange={this.handleChange}>
              <option value="">Choisir/Select Upsell Ticket de/of</option>
              {ticketListTrans}
            </select>
            {errorMessage !== null && errorMessage !== "" ? (
              <>
                <br />
                <Alert severity="error">{errorMessage}</Alert>
              </>
            ) : (
              ""
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{transactionsTr}</tbody>
            </table>
            <div className="form-group col-md-9">
              <br />
              <input
                type="button"
                onClick={this.handleCloseNeoSearch}
                className="btn btn-default"
                value={t("Close_x")}
              />{" "}
              &nbsp; &nbsp;
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  NeoSaleFormPopup = (props) => {
    const { t } = this.props;
    const myLang = t("curlang");
    const open = this.state.openNeoSaleFormPopup;
    if (!open) {
      return null;
    }

    const client_id = this.state.client_id;
    const ticket_id = this.state.ticket_id;
    const frminput = { rndidpros: undefined };

    const ticketID = this.state.ticketlistall;
    const invoiceId = this.state.ticketListRec["id"];
    //objListSales.ticketList
    const objListSales = this.state.ticketListRec;
    let ticketList = [];
    if (
      objListSales !== undefined &&
      objListSales !== null &&
      objListSales.ticketList !== undefined
    ) {
      ticketList = objListSales.ticketList;
    }

    return (
      <div>
        <Dialog
          fullWidth={"lg"}
          maxWidth={"lg"}
          open={open}
          onClose={this.handleCloseNeoSearch}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-NeoSaleFormPopup"
        >
          <DialogContent id="form-dialog-NeoSaleFormPopup">
            <h4>
              Ticket #{ticketID} &nbsp; &nbsp; &nbsp; &nbsp; Fac/Inv #
              {invoiceId}
            </h4>
            <input
              type="button"
              onClick={this.handleCloseNeoSearch}
              className="btn btn-success float-right"
              value={t("zqwNVFrmbtn_x")}
            />

            <NeoVentesForm
              frmGoXonSaleClosePopup={777}
              client_idPopup={client_id}
              ticket_idPopup={ticketID}
              invoiceIdPopup={invoiceId}
              ticketList={ticketList}
            />

            <div className="form-group col-md-9">
              <br />
              <input
                type="button"
                onClick={this.handleCloseNeoSearch}
                className="btn btn-default"
                value={t("Close_x")}
              />{" "}
              &nbsp; &nbsp;
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ NeoVentes: state.NeoVentes });
const mapDispatchToProps = {
  addOrUpdateNeoVentes,
  getNeoVentes,
  getNeoVentesAll,
  getNeoVentesAllXStaus,
  getNeoVentesAllXStausx2,
  getNeoVentesGoto,
  deleteNeoVentes,
  getOrSetMultiActionStatus,
  getSearchConflict,
  setSearchConflict,
  getNeoSearchByTicket,
  getClientAndAddress,
  getClientPaymentsFullInfo,
  getGenAndCheckTicket,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("common")(NeoVentesMySWABasicPostActions));
