import React, { useState, useEffect } from "react";
import { Spinner, Label } from "reactstrap";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { genLang } from "../../composables/genLang";

export function ProductAvailabilitiesList({
  products,
  groups,
  handleProductGroupChange,
  productAvailableLoading,
  handleGetSingleProductAvailabilities,
  groupDomains,
}) {
  // console.log(products);
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
      }}
    >
      <h6 style={{ textAlign: "center", marginBottom: "0px" }}>
        Dispo et groups
      </h6>
      {products.map(
        (i) =>
          i?.isdelete !== 1 &&
          !i?.parent && (
            <ProductGroupsCard
              {...i}
              handleGetSingleProductAvailabilities={
                handleGetSingleProductAvailabilities
              }
              productGroups={i.groups}
              groupDomains={groupDomains}
              groupOptions={groups}
              handleProductGroupChange={handleProductGroupChange}
              productAvailableLoading={productAvailableLoading}
            />
          )
      )}
    </div>
  );
}

function ProductGroupsCard({
  productTitre,
  groupOptions,
  productGroups,
  handleProductGroupChange,
  index,
  productAvailableLoading,
  availabilities,
  handleGetSingleProductAvailabilities,
  groupDomains,
}) {
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedSubgroups, setSelectedSubgroups] = useState([]);

  useEffect(() => {
    handleGetSingleProductAvailabilities(index);
    const preSelectedDomains = groupDomains.filter((i) =>
      productGroups?.includes(i.value)
    );
    const preSelectedSubgroups = subDomainsAllOptions.filter((i) =>
      productGroups?.includes(i.value)
    );
    setSelectedDomains(preSelectedDomains);
    setSelectedSubgroups(preSelectedSubgroups);
  }, [productGroups, groupOptions]);

  const handleSubgroupChange = (subgroups) => {
    setSelectedSubgroups(subgroups);
    handleProductGroupChange([...subgroups, ...selectedDomains], index);
  };

  const handleDomainChange = (domains) => {
    setSelectedDomains(domains);
    handleProductGroupChange([...domains, ...selectedSubgroups], index);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  const isLoading =
    productAvailableLoading === index || productAvailableLoading === "ALL";
  const isAvailable = availabilities?.count > 0;

  const subDomainsAllOptions = groupOptions.filter((i) => !i.domain);

  const allDomainSubgroup = subDomainsAllOptions.filter((i) => !i.parent);

  const allDomainsSubgroupGroup = {
    label: genLang({ en: "All domains", fr: "Tout domaines" }),
    options: allDomainSubgroup,
  };

  const subDomainFilteredOptions =
    groupDomains
      .filter((i) => productGroups?.includes(i.value))
      .map((i) => ({ label: i?.label, options: i?.subgroups })) ?? [];

  const subDomainFilteredOptionsWithAll = [
    allDomainsSubgroupGroup,
    ...subDomainFilteredOptions,
  ];

  return (
    <div
      style={{
        border: "1px solid lightgrey",
        width: "100%",
        padding: "0.5rem",
        borderRadius: "8px",
      }}
    >
      <div style={{ textAlign: "center", fontWeight: "600", fontSize: "14px" }}>
        {productTitre}
      </div>
      <div>
        <Label className="price-label">
          {genLang({ en: "Domains", fr: "Domaines" })}
        </Label>
        <Select
          styles={customStyles}
          isMulti
          isDisabled={isLoading}
          isLoading={isLoading}
          options={groupDomains}
          value={selectedDomains}
          onChange={(e) => handleDomainChange(e ?? [])}
        />
      </div>
      <div>
        <Label className="price-label">
          {genLang({ en: "Specialties", fr: "Spécialités" })}
        </Label>
        <Select
          styles={customStyles}
          isMulti
          isDisabled={isLoading}
          isLoading={isLoading}
          options={subDomainFilteredOptionsWithAll}
          value={selectedSubgroups}
          onChange={(e) => handleSubgroupChange(e ?? [])}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          marginTop: "0.7rem",
        }}
      >
        {isLoading ? (
          <Spinner size="sm" style={{ color: "#047bf8" }} />
        ) : (
          <FontAwesomeIcon
            icon={isAvailable ? faCheck : faTimes}
            style={{ color: isAvailable ? "green" : "red" }}
            size="lg"
          />
        )}
        {isLoading ? (
          <span style={{ fontWeight: "600" }}>
            {genLang({ en: "Loading...", fr: "En cours..." })}
          </span>
        ) : isAvailable ? (
          <span style={{ fontWeight: "600" }}>{`Disponible (${
            availabilities?.count
          } ${genLang({
            en: "Lawyers",
            fr: "Avocats",
          })})`}</span>
        ) : (
          <span style={{ fontWeight: "600" }}>{"Non disponible"}</span>
        )}
      </div>
    </div>
  );
}
