import React from "react";
import { Button } from "reactstrap";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { genLang } from "../../../../../composables/genLang";

export function DownloadCSVButton({ data }) {
  const onClick = () => {
    const config = mkConfig({ useKeysAsHeaders: true, filename: "leads" });
    const output = generateCsv(config)(data);
    download(config)(output);
  };

  return (
    <Button color="primary" onClick={onClick}>
      <FontAwesomeIcon icon={faFileCsv} style={{ marginRight: "0.5rem" }} />
      {genLang({ en: "Download CSV", fr: "Télécharger CSV" })}
    </Button>
  );
}
