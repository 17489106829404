import React from "react";
import { Redirect, Link, withRouter } from "react-router-dom";
import {
  Alert,
  Row,
  Col,
  FormGroup,
  Button,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faBackward,
  faInfoCircle,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";

import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import moment from "moment-timezone";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import _ from "lodash";
import axios from "axios";
import { axiosDefaults } from "../../../../../appConfigaxios";
import Notes from "./Notes/Notes";
import history from "../../../../../history";
import rootsActions from "../../../../_actions/root-actions";
import { BookingModal } from "./TimelineLead";
import { NewLeadButton } from "./NewLeadButton";
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;
const DEFAULT_STYLE = {};
const BACKEND1 = process.env.REACT_APP_API_URLX;

var toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    width: "100%",
    height: "32px",
    minHeight: "32px",
    maxHeight: "32px",
    textAlign: "left",
  }),
  control: (provided) => ({
    ...provided,
    height: "32px",
    minHeight: "32px",
    maxHeight: "32px",
  }),
  input: (provided) => ({
    ...provided,
    minHeight: "5px",
    maxHeight: "5px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
    color: "#757575",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "25px",
    fontSize: "10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "inline-block",
    fontSize: "15px",
    top: "55%",
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "inline-block",
    position: "absolute",
    fontSize: "15px",
    top: "50%",
  }),
};

class TimelineLeadTicket extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      id: "",
      lang: "fr",
      activeTab: "1",
      loading: false,
      readOnly: false,
      macros: [],
      value: {
        id: "ca-01",
        value: "ca-01",
        label: "Note privée",
      },
      notes: [],
      text: "<p></p>",
      raison: "",
      vente: null,
      reasons: [],
      postError: false,
      fetchError: "",
      rappelCreated: false,
      nextLead: "",
    };

    this.saveUkraine = this.saveUkraine.bind(this);
    this.fetchNotes = this.fetchNotes.bind(this);
  }

  componentDidMount() {
    axiosDefaults(null);
    let ticketId = 0;
    if (this.props.ticketId != undefined) {
      ticketId = this.props.ticketId;
    }

    axios
      .get(BACKEND1 + "/leads", {
        params: {
          id: "0",
          ticket_id: this.props.ticketId,
          showtrans: 3,
        },
      })
      .then((status) => {
        let { data } = status.data;
        if (data) {
          console.log("data", data);
          if (data.lead.status === "3") this.setState({ readOnly: true });
          const reasons = data.macros
            .filter((i) => i.type === "leadReason" && i.title !== "Vente")
            .map((i) => {
              return { label: { en: i.title_en, fr: i.title }, value: i.id };
            });
          if (data.lead && data.lead.extraction) {
            this.setState({ id: data.lead.id });
            let obj = {
              // ...data.lead.extraction,
              ticket: data.lead.ticket_id,
              leadinfo: { ...data.lead },
              leadHistory: data.leadhistory,
            };
            if (data.clientinfo !== null) {
              if (data.clientinfo.client) {
                if (!obj.clientEmail && data.clientinfo.client.email)
                  obj.clientEmail = data.clientinfo.client.email;
                if (
                  !obj.fullName &&
                  data.clientinfo.client.firstname &&
                  data.clientinfo.client.lastname
                ) {
                  obj.clientFullName = `${data.clientinfo.client.firstname} ${data.clientinfo.client.lastname}`;
                  obj.firstname = data.clientinfo.client.firstname ?? "";
                  obj.lastname = data.clientinfo.client.lastname ?? "";
                }
                if (!obj.clientPhone && data.clientinfo.client.cell)
                  obj.clientPhone = data.clientinfo.client.cell;
              }
              obj.type = "client";
              obj.transactions = data.clientinfo.transactions;
              obj.clientId = data.clientinfo.client_id;
              obj.zendesk_id = data.clientinfo.client.id_zendesk;
              if (data.clientinfo.address) {
                let str = "";
                try {
                  str += data.clientinfo.address.address;
                } catch {
                  str += "";
                }
                try {
                  str += `, ${data.clientinfo.address.city}`;
                } catch {
                  str += "";
                }
                try {
                  str += `, ${data.clientinfo.address.province}`;
                } catch {
                  str += "";
                }
                obj.clientAddress = str;
              }
            } else if (data.clientinfo === null && data.contactinfo !== null) {
              obj.type = "contact";
              obj = { ...obj, ...data.contactinfo };
            } else if (!data.clientinfo) obj.transactions = [];
            else {
              if (data.lead.extraction) {
                obj.type = "client";
                obj.clientFullName = data.lead.extraction.clientFullName;
                obj.clientEmail = data.lead.extraction.clientEmail;
                obj.clientPhone = data.lead.extraction.clientPhone;
              }
            }
            this.props.setCustomer(obj);
          }
          this.genLead();
          this.setState({
            ...data,
            reasons: reasons,
          });
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          // this.setState({ fetchError: error.response.data });
          window.location.pathname = `/leads/${this.props.ticketId}`;
        }
      });
    window.addEventListener("message", this.handleCreateCalendarNote);
    this.interval = setInterval(
      this.fetchNotes,
      process.env.FETCH_TIME || 30000
    );
  }

  handleCreateCalendarNote = (e) => {
    if (
      !["https://stg.neodoc.app", "https://www.neodoc.app"].includes(e.origin)
    )
      return;
    const data = JSON.parse(e?.data);
    const lang = "fr";
    const momentLang = `${lang}-ca`;
    const { start, duration, service, users } = data ?? {};
    const formatTime = (time) =>
      moment.utc(time).tz("America/New_York").locale(momentLang).format("LLL");
    const user = users[0] ?? {};
    const strStart = formatTime(start);
    const eventDetails = `<b>Événement calendrier<br> ${service} <br>${strStart}<br>${duration} min avec ${user?.name}`;
    axios
      .post(
        BACKEND1 + "/leads/lead",
        {
          id: this.state.id,
          actionid: "ca-03",
          note: eventDetails,
        },
        {
          params: {},
        }
      )
      .then((status) => {
        let { data } = status.data;
        if (data.notes) {
          this.setState({ notes: [...data.notes], rappelCreated: true });
        }
        this.props.toggleCalendar();
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("message", this.handleCreateCalendarNote);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  deleteRappel = () => {
    axios.delete("https://admin.neolegal.ca/api/availabilities", {
      params: {
        id: this.props.eventId,
        auth_id: localStorage.getItem("user_id"),
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
        "Content-Type": "application/json",
      },
    });
  };

  async fetchNotes() {
    const { data } = await axios.get(BACKEND1 + "/leads", {
      params: {
        //src: "desk",
        id: this.state.id,
        leadreadonly: "3",
      },
    });
    const is_same = this.state.notes.length === data.data.notes.length;
    if (is_same !== true) {
      this.setState({ notes: data.data.notes });
    }
  }

  saveUkraine(e) {
    let { value, text, raison, vente, postError } = this.state;
    const obj = {
      id: this.state.id,
      eventId: this.props.eventId,
      massActions: [],
    };
    if (
      value.value !== undefined &&
      text !== "<p></p>" &&
      text !== "<p><br></p>"
    )
      obj.massActions.push({ actionid: value.value, note: text });
    if (vente === true)
      obj.massActions.push({ actionid: "lr-0", note: "Vente/Sale" });
    if (vente === false && raison !== "")
      obj.massActions.push({ actionid: raison, note: "" });
    if (obj.massActions.length === 0) {
      this.setState({
        postError: true,
        loading: false,
      });
      postError = true;
    } else if (obj.massActions.length > 0) {
      this.setState({
        postError: false,
        loading: true,
      });
      postError = false;
    }
    // console.log(obj);
    if (!postError && obj.massActions.length) {
      axios
        .post(BACKEND1 + "/leads/lead", obj, {
          params: {},
        })
        .then((status) => {
          let { data } = status.data;
          if (data.notes) {
            this.setState({ notes: [...data.notes] });
          }
          if (vente === true) {
            history.push(`/neoventes/${this.state.id}/leadId`);
          }
          this.setState({
            text: "<p></p>",
            value: {
              id: "ca-01",
              value: "ca-01",
              label: "Note privée",
            },
            vente: null,
            raison: "",
            postError: false,
            loading: false,
          });
        });
    }
  }

  onChange(e) {
    this.setState({
      value: e,
      text: e.html,
    });
  }

  handleUnlockLead = () => {
    const userId = localStorage.getItem("user_id");
    axios
      .post(
        BACKEND1 + "/leads/leads/lead",
        {
          id: this.state.id,
          status: "2",
          id_user: userId,
        },
        {
          params: {},
        }
      )
      .then((res) => {
        const { data } = res.data;
        if (data) {
          const obj = {
            ...this.props.customer,
            leadinfo: {
              ...this.props.customer.leadinfo,
              status: "2",
              id_user: userId,
            },
          };
          this.setState(
            (prevState) => {
              return {
                ...prevState,
                lead: { ...prevState.lead, status: "2", id_user: userId },
                readOnly: false,
              };
            },
            () => this.props.setCustomer(obj)
          );
        }
      });
  };

  genLead = () => {
    const leads = JSON.parse(sessionStorage.getItem("leads"));
    console.log(leads);
    if (leads && leads.length) {
      const curIdx = _.findIndex(leads, (i) => i === this.props.id) + 1;
      if (curIdx < leads.length) {
        this.setState({ nextLead: leads[curIdx] });
      } else if (curIdx === leads.length) {
        this.setState({ nextLead: leads[0] });
      }
    }
  };

  genLang = (text) => {
    const { trad, lang } = this.props;
    return trad["neoleads"][text][lang];
  };

  render() {
    let { props } = this;
    let macros = this.state.macros.filter((i) => i.type !== "leadReason");
    let notes = this.state.notes || [];
    if (this.state.redirect) {
      return <Redirect to={{ pathname: this.state.redirect }} />;
    }
    if (props.customer && props.customer.conflict) {
      if (props.customer.conflict === "conflict") {
        macros = _.filter(macros, { type: "conflit" });
      }
    }

    return (
      <>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0rem 1rem",
          }}
        >
          <Link to={"/leads"}>
            <Button
              outline
              color="primary"
              style={{
                whiteSpace: "nowrap",
                marginTop: "2.5rem",
                marginBottom: "2rem",
                width: "185px",
                height: "32px",
              }}
              onClick={() => {}}
            >
              <FontAwesomeIcon icon={faBackward} />
              {" " + this.genLang("timelineLeadReturn")}
            </Button>
          </Link>
          <h1
            onClick={() => console.log(this.state)}
            style={{
              color: "#0052BB",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
            className="text-align-center element-header"
          >
            Timeline lead
          </h1>
          {!this.state.readOnly && !this.state.wrongAgent ? (
            <NewLeadButton />
          ) : (
            <Button
              outline
              color="primary"
              onClick={() => {
                this.handleUnlockLead();
                if (this.state.readOnly) {
                  this.handleUnlockLeadNote("open");
                } else if (this.state.wrongAgent) {
                  this.handleUnlockLeadNote("agent");
                }
              }}
              style={{
                whiteSpace: "nowrap",
                float: "right",
                marginTop: "2.5rem",
                marginBottom: "2rem",
                width: "185px",
                height: "32px",
              }}
            >
              {`${this.genLang("takeLead")} `}
              <FontAwesomeIcon icon={faUnlock} />
            </Button>
          )}
        </Row>
        {props.customer.conflict === "conflict" ? (
          <Row>
            <Col>
              <Button
                type={"button"}
                onClick={(e) => {
                  this.props.setConflict("");
                }}
              >
                Réinitialiser les conflits
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {this.state.postError && (
          <Alert
            color="danger"
            toggle={() => this.setState({ postError: false })}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            {`${this.genLang("fillOutAllFields")}`}
          </Alert>
        )}
        {this.state.fetchError && (
          <Alert color="danger">
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.state.fetchError}`}
          </Alert>
        )}
        {this.state.rappelCreated && (
          <Alert
            color="success"
            toggle={() => this.setState({ rappelCreated: false })}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.genLang("rappelCreated")}`}
          </Alert>
        )}
        {this.state.readOnly && (
          <Alert color="primary">
            <FontAwesomeIcon icon={faInfoCircle} />
            {` ${this.genLang("leadClosedMessage")}`}
          </Alert>
        )}

        <Row>
          <Col md={12} className="m-b-10">
            {!this.state.readOnly && (
              <FormGroup>
                <Select
                  id={"macroList"}
                  tabIndex={props.tabindex}
                  onChange={(e) => this.onChange(e)}
                  options={_.map(macros, (e) => {
                    return { ...e, value: e.id, label: e.title, html: e.value };
                  })}
                  value={this.state.value || []}
                  name={"macroList"}
                  key={"macroList"}
                  isDisabled={this.state.loading}
                  isSearchable
                  placeholder={this.genLang("search")}
                />
              </FormGroup>
            )}
          </Col>
        </Row>

        {!this.state.readOnly && (
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <ReactQuill
                    className="contenteditable"
                    readOnly={
                      this.props.disabled ||
                      this.state.loading ||
                      this.state.value.length === 0
                    }
                    style={
                      this.state.loading || this.state.value.length === 0
                        ? { cursor: "not-allowed" }
                        : {}
                    }
                    name={"notre_dame_des_eclaireurs"}
                    theme="snow"
                    modules={{ toolbar: toolbarOptions }}
                    value={this.state.text || ""}
                    onChange={(e, a, b, c) => {
                      // let foun = e.match(QL_CURSOR)
                      let clean = e.replace(QL_CURSOR, "");
                      this.setState({ text: clean });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        )}
        {/* ROW FOR SALES BUTTON */}
        {!this.state.readOnly && (
          <Row md={12} style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Col md={6}>
              <Button
                outline={this.state.vente !== true}
                // size="sm"
                color="primary"
                disabled={this.state.loading}
                style={
                  this.state.loading
                    ? {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        cursor: "not-allowed",
                        height: "32px",
                      }
                    : {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        height: "32px",
                      }
                }
                onClick={() => {
                  const vente = this.state.vente === true ? null : true;
                  this.setState({
                    vente: vente,
                  });
                }}
              >
                {`${this.genLang("sale")}`} <FontAwesomeIcon icon={faCheck} />
              </Button>

              <Button
                outline={this.state.vente !== false}
                // size="sm"
                disabled={this.state.loading === true}
                color="primary"
                style={
                  this.state.loading
                    ? {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        cursor: "not-allowed",
                        height: "32px",
                      }
                    : {
                        marginRight: "0.3rem",
                        whiteSpace: "nowrap",
                        width: "32%",
                        height: "32px",
                      }
                }
                onClick={(e) => {
                  const vente = this.state.vente === false ? null : false;
                  this.setState({
                    vente: vente,
                  });
                }}
              >
                {`${this.genLang("noSale")} `}{" "}
                <FontAwesomeIcon icon={faTimes} />
              </Button>

              <BookingModal
                setReadOnly={() => this.setState({ readOnly: true })}
                vente={this.state.vente}
                loading={this.state.loading}
                customer={this.props.customer}
                leadId={this.props.id}
              />
            </Col>

            <Col md={3}>
              <Select
                options={this.state.reasons.map((i) => {
                  return { ...i, label: i.label[this.props.lang] };
                })}
                styles={customStyles}
                isDisabled={
                  this.state.vente === true ||
                  this.state.vente === null ||
                  this.state.loading === true
                }
                onChange={(e) => this.setState({ raison: e.value })}
              />
            </Col>

            <Col md={3}>
              <Button
                outline
                style={
                  this.state.loading
                    ? { cursor: "not-allowed", height: "32px" }
                    : { height: "32px" }
                }
                disabled={this.state.loading}
                color="primary"
                // size="sm"
                block={true}
                onClick={this.saveUkraine}
                type="button"
              >
                {this.state.loading ? (
                  <div>
                    {`${this.genLang("loading")}`}{" "}
                    <Spinner color="primary" size="sm" />
                  </div>
                ) : (
                  `${this.genLang("register")}`
                )}
              </Button>
            </Col>
          </Row>
        )}
        <Notes notes={this.state.notes} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rootsActions: rootsActions,
  loading: state.loading.loading,
  request: state.request,
  customer: state.customer,
});

const mapActionsToProps = {
  setCustomer: rootsActions.customersActions.setUser,
  setConflict: rootsActions.customersActions.setConflict,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(TimelineLeadTicket));
