import React, { Component, useEffect } from "react";
import { APP_PATHNAME } from "./appConfig";
import {
  Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import history from "./history";
import { decryptText } from "./appEncryption";

import { BaseFooter } from "./BaseFooter";

import { BaseHeader2 } from "./BaseHeader2";
import { BaseLeftPanel2 } from "./BaseLeftPanel2";

import { connect, useSelector } from "react-redux";
import {
  getProfileFetch,
  logoutUser,
  getLoggedUser,
  setLoggedUser,
} from "./redux/actions";
import Signup from "./Signup";
import Login from "./Login";
import VwBoard from "./components/vwfacturas/VwBoard";
import { getDailyGoalSale } from "./actions/NeoSalesPlanningDailyAction";

import { withTranslation } from "react-i18next";

import ZxCompaniesList from "./components/zxcompanies/ZxCompaniesList";
import ZxCompaniesInfo from "./components/zxcompanies/ZxCompaniesInfo";
import ZxCompaniesForm from "./components/zxcompanies/ZxCompaniesForm";

import VwFacturasList from "./components/vwfacturas/VwFacturasList";
import VwFacturasInfo from "./components/vwfacturas/VwFacturasInfo";
import VwFacturasForm from "./components/vwfacturas/VwFacturasForm";

import ZonlineUsuariosList from "./components/zonlineusuarios/ZonlineUsuariosList";
import ZonlineUsuariosInfo from "./components/zonlineusuarios/ZonlineUsuariosInfo";
import ZonlineUsuariosForm from "./components/zonlineusuarios/ZonlineUsuariosForm";

import NeoVentesList from "./components/neoventes/NeoVentesList";
import PendingNeoVentesList from "./components/neoventes/PendingNeoVentesList";
import NeoVentesInfo from "./components/neoventes/NeoVentesInfo";
import NeoVentesForm from "./components/neoventes/NeoVentesForm";
import NeoVentesListx from "./components/neoventes/NeoVentesListx";
import NeoVentesListupsell from "./components/neoventes/NeoVentesListupsell";
import NeoVentesb2bList from "./components/neoventes/NeoVentesb2bList";
import NeoVentesSearchConfList from "./components/neoventes/NeoVentesSearchConfList";
import NeoVentesBasicPostActions from "./components/neoventes/NeoVentesBasicPostActions";
import NeoVentesSWABasicPostActions from "./components/neoventes/NeoVentesSWABasicPostActions";
import NeoVentesMySWABasicPostActions from "./components/neoventes/NeoVentesMySWABasicPostActions";

import NeoSalesPlanningDailyList from "./components/neosalesplanningdaily/NeoSalesPlanningDailyList";
import NeoSalesPlanningWeeklyList from "./components/neosalesplanningweekly/NeoSalesPlanningWeeklyList";
import NeoSalesPlanningSellerList from "./components/neosalesplanningweekly/NeoSalesPlanningSellerList";
import NeoSalesPlanningWeeklyForm from "./components/neosalesplanningweekly/NeoSalesPlanningWeeklyForm";

import CheckoutEmailEmulator from "./CheckoutEmailEmulator";
import NeoB2bleadProspectsList from "./components/neob2bleadprospects/NeoB2bleadProspectsList";
import NeoB2bleadProspectsListrep from "./components/neob2bleadprospects/NeoB2bleadProspectsListrep";

import ZendLeadsList from "./components/zendleads/ZendLeadsList";
import ZendLeadsInfo from "./components/zendleads/ZendLeadsInfo";
import ZendLeadsForm from "./components/zendleads/ZendLeadsForm";
import ZendMedList from "./components/zendleads/ZendMedList";
import ZendLeadmedsForm from "./components/zendleads/ZendLeadmedsForm";

import SendSMSNoteForm from "./components/neoventes/SendSMSNoteForm";
import NeoProspectsSmsList from "./components/neoprospectssms/NeoProspectsSmsList";
import trad from "./srcincorpo/trad/trad";
import LeadDetails from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadDetails";

import LeadList from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadList";
import LeadListAdmin from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadListAdmin";
import LeadUserAdmin from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadUserAdmin";
import Lead7 from "./srcincorpo/UPDATED_testament/inputComponents/sales/Lead7";
import ContactDetails from "./srcincorpo/UPDATED_testament/inputComponents/sales/ContactDetails";
import LeadDetailsTicket from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadDetailsTicket";
import LeadListclose from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadListclose";
import LeadListAdminsuivi from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadListAdminsuivi";

import MacroEditor from "./srcincorpo/UPDATED_testament/MacroEditor/MacroEditor";
import MergeTickets from "./srcincorpo/UPDATED_testament/MergeTickets/MergeTickets";
import AdminPrices from "./srcincorpo/UPDATED_testament/AdminPrices/AdminPrices";
import TopMenu from "./srcincorpo/UPDATED_testament/inputComponents/sales/component/TopMenu";
import { Row } from "reactstrap";

class App extends Component {
  componentWillUnmount = () => {
    this.props.getProfileFetch();
  };

  render() {
    const MyAppMain = AppMain;
    const path = APP_PATHNAME; //document.URL.substr(0,document.URL.lastIndexOf('/'));
    return (
      <Router history={history}>
        <div>
          <Switch>
            <Route exact path={path + "xcvbbb"} component={Login} />
            <Route path={path + "signup"} component={Signup} />
            <Route path={path + "login"} component={Login} />
            <Route
              path={path + "viewemailinvoice"}
              component={CheckoutEmailEmulator}
            />
            <PrivateRoute
              path={path + "protected"}
              {...this.props}
              component={MyAppMain}
            />
            <PrivateRoute path="/" {...this.props} component={MyAppMain} />
          </Switch>
        </div>
      </Router>
    );
  }
}


const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.LoggedUser.currentUser);
  let isLoggedIn = false;
  let isAuthenticated = false;
  const tokenExist = localStorage.token;
  const rawUserExist = localStorage.user;

  isLoggedIn = user.isAuthenticated;
  const currentUserx = user.currentUser; //user.currentUser;
  let userId = user.id;
  const token = decryptText(localStorage.token);
  const rawUser = decryptText(localStorage.user);

  if (
    isLoggedIn === undefined &&
    userId === undefined &&
    token !== undefined &&
    token !== null
  ) {
    const userStr = rawUser.split(":");
    const rawUserx = {
      id: userStr[1],
      name: userStr[2],
      role: userStr[3],
      isAuthenticated: true,
    }; //rawUser; //JSON.parse(rawUser);
    let neoState = {
      currentUser: rawUserx,
      id: userStr[1],
      name: userStr[2],
      role: userStr[3],
      isAuthenticated: true,
    };
    //console.log("RAAAAAAAAAAAAAW DATA "+rawUserx.isAuthenticated+" "+rawUserx.id+" "+rawUser);
    if (rawUserx.id > 0) {
      isLoggedIn = true;
      userId = rawUserx.id;
      setLoggedUser(neoState);
    }
  }
  if (
    isLoggedIn === true &&
    userId > 0 &&
    token !== undefined &&
    token !== null
  ) {
    isAuthenticated = true;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: APP_PATHNAME + "login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

function transformJSONStr(str) {
  const x = str.split(",");
  let xq = {};
  for (let k = 0; k < x.length; k++) {
    const kv = x[k].split(":");
    xq[kv[0]] = kv[1];
  }

  return xq;
}

function getProtectedThing() {
  return (dispatch, getState) => {
    const state = getState();

    const user = state.currentUser;

    return user;
  };
}

class AppMain extends Component {
  _isMounted = false; //true;
  script = null;

  constructor(props) {
    super(props);
    this.state = { isLeftMenuOpen: false, isLeftMenuOpen2: false };
    let path = this.props.location; //this.props.path;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleLeftPanelMenu(event) {
    event.preventDefault();
    let valMenuToggle = true;
    if (this.state.isLeftMenuOpen) {
      valMenuToggle = false;
    } else {
      valMenuToggle = true;
    }
    if (this._isMounted) {
      this.setState({
        isLeftMenuOpen: valMenuToggle,
      });
    }
  }

  handleLeftPanelMenu2(event) {
    let valMenuToggle = true;
    if (this.state.isLeftMenuOpen2) {
      valMenuToggle = false;
    } else {
      valMenuToggle = true;
    }
    if (this._isMounted) {
      this.setState({
        isLeftMenuOpen2: valMenuToggle,
      });
    }
  }

  handleLeftPanelMenu2x(event) {
    let valMenuToggle = false;
    if (this._isMounted) {
      this.setState({
        isLeftMenuOpen2: valMenuToggle,
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div
        className={
          this.state.isLeftMenuOpen === true
            ? "hold-transition sidebar-mini"
            : "sidebar-mini sidebar-collapse"
        }
        id="topHomeFirst"
      >
        <div className="wrapper">
          <BaseHeader2
            handleLeftPanelMenuRef={this.handleLeftPanelMenu2.bind(this)}
          />
          <BaseLeftPanel2
            openLeftMenu={this.state.isLeftMenuOpen2}
            handleLeftPanelMenuRefx={this.handleLeftPanelMenu2x.bind(this)}
          />

          <div className="content-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <Row>
                    <TopMenu />
                  </Row>
                  <div className="card-body">
                    <Main {...this.props} />
                    <div>
                      &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <BaseFooter />
        </div>
      </div>
    );
  }
}

const Main = (props) => {
  let { path, url } = useRouteMatch();
  const mathparams = useRouteMatch();
  const pathprev = path;
  path = APP_PATHNAME;
  return (
    <Switch>
      <Route exact path={["/", "/dashboard"]} component={LeadList} />
      <Route exact path={path + "zxcompanies"} component={ZxCompaniesList} />
      <Route
        exact
        path={path + "zxcompanies/new"}
        component={ZxCompaniesForm}
      />
      <Route
        exact
        path={path + "zxcompanies/:id"}
        component={ZxCompaniesInfo}
      />
      <Route
        exact
        path={path + "zxcompanies/:id/edit"}
        component={ZxCompaniesForm}
      />

      <Route
        exact
        path={path + "zonlineusuarios"}
        component={ZonlineUsuariosList}
      />
      <Route
        exact
        path={path + "zonlineusuarios/new"}
        component={ZonlineUsuariosForm}
      />
      <Route
        exact
        path={path + "zonlineusuarios/:id"}
        component={ZonlineUsuariosInfo}
      />
      <Route
        exact
        path={path + "zonlineusuarios/:id/edit"}
        component={ZonlineUsuariosForm}
      />

      <Route exact path={path + "vwfacturas"} component={VwFacturasList} />
      <Route exact path={path + "vwfacturas/new"} component={VwFacturasForm} />
      <Route exact path={path + "vwfacturas/:id"} component={VwFacturasInfo} />
      <Route
        exact
        path={path + "vwfacturas/:id/edit"}
        component={VwFacturasForm}
      />

      <Route exact path={path + "neoventes"} component={NeoVentesForm} />
      <Route
        exact
        path={path + "neoventes/:id/invoice"}
        component={NeoVentesForm}
      />
      <Route
        exact
        path={path + "neoventes/:id/:ticket/onSaleClose"}
        component={NeoVentesForm}
      />

      <Route exact path={path + "vwneoventes"} component={NeoVentesList} />
      <Route
        exact
        path={path + "vwneoventespending"}
        component={PendingNeoVentesList}
      />
      <Route exact path={path + "neoventes/new"} component={NeoVentesForm} />
      <Route exact path={path + "neoventes/:id"} component={NeoVentesInfo} />
      <Route
        exact
        path={path + "neoventes/:id/edit"}
        component={NeoVentesForm}
      />
      <Route
        exact
        path={path + "neoventes/:idl/leadId"}
        component={NeoVentesForm}
      />
      <Route
        exact
        path={path + "neoventes/:idc/:idl/:idt/fromlead"}
        component={NeoVentesForm}
      />
      <Route exact path={path + "allstatussales"} component={NeoVentesListx} />
      <Route
        exact
        path={path + "vwneoventesupsell"}
        component={NeoVentesListupsell}
      />
      <Route
        exact
        path={path + "vwneoventesb2b"}
        component={NeoVentesb2bList}
      />
      <Route exact path={path + "sendsmscallfrm"} component={SendSMSNoteForm} />

      <Route
        exact
        path={path + "salesplanningdaily"}
        component={NeoSalesPlanningDailyList}
      />
      <Route
        exact
        path={path + "salesplanningweekly"}
        component={NeoSalesPlanningWeeklyList}
      />

      <Route
        exact
        path={path + "salesplanningpeople"}
        component={NeoSalesPlanningSellerList}
      />
      <Route
        exact
        path={path + "salesplanningweekly/new"}
        component={NeoSalesPlanningWeeklyForm}
      />
      <Route
        exact
        path={path + "salesplanningweekly/:id/edit"}
        component={NeoSalesPlanningWeeklyForm}
      />

      <Route
        exact
        path={path + "prospectlist"}
        component={NeoB2bleadProspectsList}
      />
      <Route
        exact
        path={path + "prospectreport"}
        component={NeoB2bleadProspectsListrep}
      />

      <Route
        exact
        path={path + "searchconflictlist"}
        component={NeoVentesSearchConfList}
      />
      <Route
        exact
        path={path + "basicPostSellactions"}
        component={NeoVentesBasicPostActions}
      />
      <Route
        exact
        path={path + "basicSWAPostSellactions"}
        component={NeoVentesSWABasicPostActions}
      />

      <Route exact path={path + "zendleads"} component={ZendLeadsList} />
      <Route exact path={path + "myzendleads"} component={ZendLeadsList} />
      <Route exact path={path + "zendleads/new"} component={ZendLeadsForm} />
      <Route exact path={path + "zendleads/:id"} component={ZendLeadsInfo} />
      <Route
        exact
        path={path + "zendleads/:id/edit"}
        component={ZendLeadsForm}
      />
      <Route exact path={path + "zendmeds"} component={ZendMedList} />
      <Route
        exact
        path={path + "zendmeds/:id/edit"}
        component={ZendLeadmedsForm}
      />
      <Route
        exact
        path={path + "prospectssmslist"}
        component={NeoProspectsSmsList}
      />

      <Route
        exact
        path={path + "leads"}
        render={(route) => {
          return <LeadList trad={trad} history={history} />;
        }}
      />
      <Route
        exact
        path={path + "leads/to-complete"}
        render={(route) => {
          return <NeoVentesMySWABasicPostActions trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/closed"}
        render={(route) => {
          return <LeadListclose trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/admin"}
        render={(route) => {
          return <LeadListAdmin trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/adminsuivi"}
        render={(route) => {
          return <LeadListAdminsuivi trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "users/admin"}
        render={(route) => {
          return <LeadUserAdmin trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/:id"}
        render={(route) => {
          return (
            <LeadDetails {...props} id={route.match.params.id} trad={trad} />
          );
        }}
      />
      <Route
        exact
        path={path + "leads/:id/:ta/:eventId"}
        render={(route) => {
          return (
            <LeadDetails
              {...props}
              id={route.match.params.id}
              ta={route.match.params.ta}
              eventId={route.match.params.eventId}
              trad={trad}
            />
          );
        }}
      />
      <Route
        exact
        path={path + "agent/tickets/:id"}
        render={(route) => {
          return (
            <LeadDetailsTicket
              {...props}
              trad={trad}
              id={route.match.params.id}
            />
          );
        }}
      />
      <Route
        exact
        path={path + "contact"}
        render={(route) => {
          return <ContactDetails trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "macros"}
        render={(route) => {
          return <MacroEditor trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "merge-tickets"}
        render={(route) => {
          return <MergeTickets trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "prices"}
        render={(route) => {
          return <AdminPrices trad={trad} />;
        }}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.LoggedUser.currentUser,
  testFXz: "wewelebret",
});

const mapDispatchToProps = (dispatch) => ({
  getProfileFetch: () => dispatch(getProfileFetch()),
  logoutUser: () => dispatch(logoutUser()),
  getDailyGoalSale: () => dispatch(getDailyGoalSale()),
  loggedUser: getLoggedUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
