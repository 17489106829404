import history from '../history';
import { API_DOMAIN } from '../appConfig'; 
import { encryptText, decryptText } from '../appEncryption';
import { getSAASToken } from '../composables/saasToken';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RECEIVE_LOGGEDUSER = 'RECEIVE_LOGGEDUSER';
export const CHANGE_USERPASS = 'CHANGE_USERPASS'; 

const apiUrl = API_DOMAIN; // + '/zxteams'; 
  
			
export const userLoginFetch = user => {
  //console.log("//////START ");  //console.log(user);  //console.log("//////////END OF USER");
  //response.status
  return dispatch => {
    return fetch(`${apiUrl}/auth`, {   
      method: "POST",
      headers: {
        'Content-Type': 'application/json', 
        Accept: 'application/json',
      },
      body: JSON.stringify(user)
    })
      //.then(resp => resp.json())
      .then(function(response) {
        if (!response.ok) {
          const error=`HTTP error! status: ${response.status}`;
          //return Promise.Reject(error);
          return Promise.reject(new Error(error));
        }
        return response.json();
      })
      .then(datax => { 
          const data = datax.data;  
          localStorage.setItem("token", encryptText(data.token));
          // console.log(data);
          const tokadm = localStorage.tokadm; 
          if(tokadm===undefined || tokadm===null){}else{ localStorage.removeItem("tokadm"); } 
          let neoState = {
            currentUser: data.profile,
            id: data.profile.id, name: data.profile.firstname, role: data.profile.user_type, 
            isAuthenticated:true
          };
          const userStr="true:"+data.profile.id+":"+data.profile.firstname+" "+data.profile.lastname+":"+data.profile.user_type+":"+data.id; 
            
          localStorage.setItem("user", encryptText(userStr)); //JSON.stringify(neoState)
          localStorage.setItem("profile", encryptText(JSON.stringify(data.profile))); //JSON.stringify(neoState)
          localStorage.setItem("profileperms", encryptText(JSON.stringify(data.permissions)));  
          localStorage.setItem("admin_token", data.token);
          getSAASToken(data.token);
          if (data?.profile) {
            localStorage.setItem("user_type", data.profile.user_type);
            localStorage.setItem("user_id", data.profile.id_users);
          }
              //return dispatch(loginUser(data.user));  
              //console.log("peeeeeerms", data.permissions);
          return dispatch(loginUser(neoState));   
		 
      })
      .catch(error => {
        console.error(error)
      })
  }
}

export const getProfileFetch = () => {
  return dispatch => {
    const token = decryptText(localStorage.token);
	////console.log("###******* TOKEN SAVED "+token);  
  const tokenRid = localStorage.rid; 
  const profile = decryptText(localStorage.profile);
  //console.log("###******* PROFILE DEC  "+profile);
  if(profile===undefined || profile===null || profile==""){ 
    return dispatch(loginUser(null));  
    //return null; 
  }
  const profileLoc=JSON.parse(profile); 
  
  if(profileLoc.id >0){
    let neoState = {
      currentUser: profileLoc,
      id: profileLoc.id, name: profileLoc.firstname+' '+profileLoc.lastname, role: profileLoc.user_type, 
      isAuthenticated:true
    };
    //return dispatch(loginUser(data.user));  
    //console.log("weeeeeeeeee return local profile....");
    return dispatch(loginUser(neoState));
  }
	
    if (token) {
      return fetch(`${apiUrl}/profile`, {   //return fetch(`${apiUrl}/profile`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
		  'rid': `${tokenRid}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.success==1 || data.success===true) { 
				//console.log("//////******* #2 SUCCESS "+data.user.id);  
			  //localStorage.setItem("token", data.token);
			   let neoState = {
				  currentUser: data.user,
				  id: data.user.id, name: data.user.name, role: data.user.type, 
				  isAuthenticated:true
				};
			  //return dispatch(loginUser(data.user));  
			  return dispatch(loginUser(neoState));  
			}else{
				// An error will occur if the token is invalid.
				// If this happens, you may want to remove the invalid token.
			  //localStorage.removeItem("token"); 
			  alert("Error ... "+data.message);
			}
			
		  /*if (data.message) {
            // An error will occur if the token is invalid.
            // If this happens, you may want to remove the invalid token.
            localStorage.removeItem("token")
          } else {
            dispatch(loginUser(data.user))
          }*/
        })
    }
  }
}

export const userPostFetch = user => {
  return dispatch => {
    return fetch(`${apiUrl}/register`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.success==1 || data.success===true) { 
			//console.log("//////******* SUCCESS "+data.user.id);  
          localStorage.setItem("token", data.token);
          //return dispatch(loginUser(data.user)); 
		  let neoState = {
			  currentUser: data.user,
			  id: data.user.id, name: data.user.name, role: data.user.type, 
			  isAuthenticated:true
			};
		  //return dispatch(loginUser(data.user));  
		  return dispatch(loginUser(neoState));  
        }else{
			// Here you should have logic to handle invalid creation of a user.
          // This assumes your Rails API will return a JSON object with a key of
          // 'message' if there is an error with creating the user, i.e. invalid username
		  alert("Error ... "+data.message);
		}
		
		/*if (data.message) {
          // Here you should have logic to handle invalid creation of a user.
          // This assumes your Rails API will return a JSON object with a key of
          // 'message' if there is an error with creating the user, i.e. invalid username
        } else {
          localStorage.setItem("token", data.token)
          dispatch(loginUser(data.user))
        }*/
      })
  }
}

export const getLoggedUser = () => {
	 //console.log("RIGHT IN loading getLoggedUser .... "); 
	 //return axios.get(`${apiUrl}/${id}`)
	/*return (dispatch) => {
		dispatch({type: RECEIVE_LOGGEDUSER, currentUserx: {id:0, name:'', isAuthenticated: false}});
	};*/ 
	
	return (dispatch, getState) => {
    // grab current state
    const state = getState();
 
    // (obviously depends on how your store is structured)
    //const authToken = state.currentUser.token;
	
	return dispatch({type: RECEIVE_LOGGEDUSER, currentUser: state.currentUser});
	

    // Pass the token to the server
    /*return fetch('/user/thing', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(res => res.json());*/
  };
};

export const setLoggedUser = (neoState) => {
	 //console.log("SET LOGGED USE TO NOT GO ON SERVER RIGHT IN loading getLoggedUser .... ");  
	
	return (dispatch) => { 
		dispatch(loginUser(neoState)) 
  };
  
  /*
  return (dispatch) => { 
		return dispatch(loginUser(neoState));  
	return dispatch({type: RECEIVE_LOGGEDUSER, currentUser: state.currentUser});
	 
  };
  */
};

/*export function getProtectedThing() {
  return (dispatch, getState) => {
    // grab current state
    const state = getState();

    // get the JWT token out of it
    // (obviously depends on how your store is structured)
    const authToken = state.currentUser.token;

    // Pass the token to the server
    return fetch('/user/thing', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }).then(res => res.json());
  }
}*/

export const getLoggedUserGoto = (id) => {
	 //console.log("BEFORE loading response gooooing to protected area .... "+id); 
	 //return axios.get(`${apiUrl}/${id}`)  history.push(`./zxteams/${id}/edit`);
	 return (dispatch) => {
     history.push(`./protected`);
  };
	 //history.push(`./ZxTeams/${id}/edit`);
};

const loginUser = userObj => ({
    type: 'LOGIN_USER',
    payload: userObj
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER'
});

export const changePassProfile = (passFieldsObj, showError) => {
  
	const token = localStorage.token;
	////console.log("###******* TOKEN SAVED "+token);  
	const tokenRid = localStorage.rid; 
	
  return dispatch => {
    return fetch(`${apiUrl}/changepassword`, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
		  'rid': `${tokenRid}`
      }, 
      body: JSON.stringify(passFieldsObj)
    })
      .then(resp => resp.json())
      .then(data => {
		//return data;
		//return dispatch({type: CHANGE_USERPASS, User: data});
		if (data.success==1 || data.success===true) { 
			return dispatch({type: CHANGE_USERPASS, User: data});  
        }else{  
		  //alert("Error ... "+data.message);
		  showError(data.message);
		  //return null;
		  //return data.message;
		  return dispatch({type: CHANGE_USERPASS, User: null});  
		}
      })
  }
   
  
}

//userLogoutProfile
export const userLogoutProfile = (someFieldsObj, showError) => {
  
	const token = decryptText(localStorage.token);
	////console.log("###******* TOKEN SAVED "+token);  
	const tokenRid = localStorage.rid; 
	
  return dispatch => {
    return fetch(`${apiUrl}/logout`, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
		  'rid': `${tokenRid}`
      }, 
      body: JSON.stringify(someFieldsObj)
    })
      .then(resp => resp.json())
      .then(data => {
		//return data;
		//return dispatch({type: CHANGE_USERPASS, User: data});
		if (data.success==1 || data.success===true) { 
			return dispatch({type: LOGOUT_USER, User: data});  
        }else{  
		  //alert("Error ... "+data.message);
		  showError(data.message);
		  //return null;
		  //return data.message;
		  return dispatch({type: LOGOUT_USER, User: null});  
		}
      })
  }
}
 
  export const userPassResetFetch = user => {
	////console.log("//////START ");  //console.log(user);  //console.log("//////////END OF USER");
	//console.log("//////////userPassResetFetch");
  return dispatch => {
    return fetch(`${apiUrl}/loginpassreset`, {  //return fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json', 
        Accept: 'application/json',
      },
      body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.success==1 || data.success===true) { 
			//console.log("//////******* SUCCESS "+data.user.id);  
          //localStorage.setItem("token", data.token);
		  let neoState = {
			  currentUser: data.user,
			  id: data.user.id, name: data.user.name, role: data.user.type, 
			  isAuthenticated:true
			};
			const userStr="true:"+data.user.id+":"+data.user.name+":"+data.user.type; 
			   
			//localStorage.setItem("user", userStr); //JSON.stringify(neoState)
          //return dispatch(loginUser(data.user));  
		  return dispatch(loginUser(neoState));  
        }else{
			// Here you should have logic to handle invalid login credentials.
          // This assumes your Rails API will return a JSON object with a key of
          // 'message' if there is an error
		  alert("Error Reseteando tu contraseña ... "+data.message);
		} 
      })
  }
}
   
  


