import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFireAlt,
  faThermometerHalf,
  faSnowflake,
} from "@fortawesome/free-solid-svg-icons";
import { genLang } from "../../../../../composables/genLang";
import MenuIconButton from "../../../IconButton/MenuIconButton";
import { Tooltip } from "@mui/material";

const thermoMap = {
  1: {
    icon: faSnowflake,
    title: genLang({ en: "Cold", fr: "Froid" }),
    value: "1",
    color: "lightBlue",
  },
  2: {
    icon: faThermometerHalf,
    title: genLang({ en: "Medium", fr: "Moyen" }),
    value: "2",
    color: "red",
  },
  3: {
    icon: faFireAlt,
    title: genLang({ en: "Hot", fr: "Chaud" }),
    value: "3",
    color: "orange",
  },
};

export default function PriorityIconToggle({
  priority,
  handleChangePriority,
  id,
}) {
  const generateMenuLabel = (priority) => (
    <div>
      <FontAwesomeIcon
        icon={thermoMap[priority].icon}
        style={{ color: thermoMap[priority].color, marginRight: "1rem" }}
      />
      {thermoMap[priority].title}
    </div>
  );

  const menuOptions = Object.values(thermoMap).map(({ value }) => ({
    label: generateMenuLabel(value),
    value: value,
    onClick: () => handleChangePriority(id, value),
  }));

  const thermoDetails = thermoMap[priority];

  return (
    <MenuIconButton
      size="lg"
      style={{ color: thermoDetails.color }}
      icon={thermoDetails.icon}
      tooltip={thermoDetails.title}
      menuOptions={menuOptions}
    />
  );
}

export function genThermoIcon(priority) {
  if (!priority) return "";
  const thermoDetails = thermoMap[priority] || {};
  if (thermoDetails?.icon) {
    return (
      <Tooltip title={thermoDetails?.title}>
        <span>
          <FontAwesomeIcon
            size="lg"
            icon={thermoDetails?.icon || null}
            style={{
              color: thermoDetails?.color,
              marginRight: "0.3rem",
            }}
          />
        </span>
      </Tooltip>
    );
  }
  return "";
}

export function genThermoString(priority) {
  if (!priority) return "";
  const thermoDetails = thermoMap[priority] || {};
  return thermoDetails?.title || "";
}
