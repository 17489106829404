import React from 'react';

 const trad = {
  formWitness:{
    en:'Witnessed will',
    fr:'Formulaire testament devant témoins'
  },
  formStep:{
    fr:'Veuillez suivre les étapes suivantes afin de générer votre testament automatiquement :',
    en:'Please complete the following steps to generate your will:'
  },
  formStepComplete:{
    fr:'Complétez les champs du formulaire selon votre situation personnelle',
    en:'Complete the fields according to your personal situation'
  },
  
  formStepPress:{
    fr:'Appuyez sur le bouton vert en haut de la page à droite',
    en:'Click on the green button located on the upper right-hand side '
  },

  formStepVisual:{
    fr:'Visualisez votre testament et faites les modifications nécessaires',
    en:'Preview your will and make adjustments as necessary'
  },

  formStepDownload:{
    fr:'Télécharger votre testament en format Word ou PDF pour impression',
    en:'Download your will as a DOC or PDF to print'
  },

  formStepSign:{
    fr:'Signez votre testament devant 2 témoins',
    en:'Sign your testament in front of two witnesses'
  },

  testatorDefinition:{
    fr:'La personne qui à sa mort désire faire connaître ses volontés par le biais de ce formulaire',
    en:'The author of the will'
  },
  addHeirSousOrdre:{
    fr:'Ajouter un héritier en sous-ordre',
    en:'Add heirs (in order) '
  },
  mainLiquidator:{
    fr:'Liquidateurs principaux ',
    en:'Primary liquidators'
  },
  backupLiquidator:{
    fr:"Liquidateur de remplacement",
    en:'Secondary liquidators'
  },
  tutorDatif:{
    fr:'Tuteur Datif (une seule personne)',
    en:'Dative Tutorship (a single person)'
  },

  tutorDatifBackup:{
    fr:'Remplaçants en cas d\'indisponibilité',
    en:'Replacements in case of absence'
  },

  tutorDatifBackupText:{
    fr:'Veuillez ajouter des remplacements au cas ou le la personne nommée en tant que tuteur datif ne puisse pas mener à bien ce mandat.',
    en:'Please indicate substitutes in case the person appointed as the Dative Tutor cannot carry out this mandate.'
  },

  animal:{
    fr:'Animaux de compagnie',
    en:'Pets'
  },

  animalOwnership:{
    fr:'Possédez-vous des animaux de compagnie ?',
    en:'Do you have any pets?'
  },

  animalToWhom:{
    fr:'À qui laissez-vous la garde ?',
    en:'To whom does the responsibility of your pet go?'
  },
  endOfForm:{
    fr:'Fin du formulaire',
    en:'End of form'
  },
  documentLang:{
    fr:'Langue du document',
    en:'Document language'
  },
  heirDefinition:{
    fr:'Liste des héritiers (legs universel)',
    en:'List of heirs (universal bequest)'
  },
  heirDescendent:{
    fr:'Les descendants des héritiers remplacent ceux-ci en cas de décès',
    en:'The descendants of the heirs take over in the event of death'
  },
  heirSousOrdre:{
    fr:'Héritiers en sous-ordre',
    en:'Heirs (in order)'
  },
  Anglais:{
    fr:'Anglais',
    en:'English'
  },
  Francais:{
    fr:'Français',
    en:'French'
  },
    inheritChildren:{
      en:'Their children',
      fr:'Ses enfants'
    },
    legacyBackup:{
      en:'Other',
      fr:'Autre'
    },
    backup:{
  
      en:'Backup',
      fr:'Remplaçant'
    
    },
    sharedEqualy:{
      en:'Shared equally amongst other heirs',
      fr:'"Partagé à part égale parmi les autres héritiers"'
    },
    diesBeforeInherit:{
      fr:'Si cette personne décède avant vous, qui héritera de sa part ?',
      en:'If this person dies before you, who will receive their portion of inheritance?'
    },
    foreignRegime:{
      fr:'régime étranger',
      en:'foreign regime'
    },
    goodsCommunity:{
      fr:'Communautés de biens',
      en:'Community of property'
    },
    universalShare:{
      fr:'Partage du bien universel',
      en:'Sharing of property',
    },
    equalShare:{
      fr:'À parts égales',
      en:'At equal share'
    },
    property:{
      en:'Property',fr:"Bien"
    },
    sum:{
      en:'Sum ',fr:"Somme"
    },
    vehicle:{
      en:'Vehicles',fr:"Tout automobile que je possède à mon décès"
    },
    
    vehicle_specific:{
      en:'Specified Vehicle',fr:"Automobile spécifique"
    },
    house: {
      en:'Real estate asset',fr:"Immeuble"
    },
    house_specific: {
      en:'Specified real estate asset',fr:"Immeuble spécifique"
    },
    vacant:{
      en:'Vacant land',fr:"Terrain vacant"
    },
    jewelAll:{
      en:'Jewelry',fr:"L'ensemble des bijoux"},
    jewel:{
      en:'A specific piece of jewelry',fr:"Bijoux en particulier"
    },
    paintings:{
      en:'Paintings',fr:"Tableaux ou peinture"
    },
    tools:{
      en:'Tools',fr:"Outils"
    },
    RESPS:{
      en:'RESPs',fr:"REÉÉ"
    },
    registeredSaving:{
      en:'Registered Retirement Savings Plan',fr:"régime enregistré d’épargne retraite (REER)"
    },
    celi:{
      en:'Tax-Free Savings Account',fr:"compte d'épargne libre d'impôt (CELI)"
    },
    registeredRetirement:{
      en:'Registered Retirement Income Fund',fr:"fonds enregistré de revenu de retraite (REER)"
    },
    registeredPension:{
      en:'Registered Pension Plans or Deferred Profit Sharing plan',fr:"régime de pension agréé ou régime de participation différée"
    },
    universal:{
      fr:'universel',
      en:'universal'
    },
    particular:{
      fr:'Particulier',
      en:'Individual'
    },
    bequestHelp:{
      fr:<>le légataire universel bénéficie de tous les biens légués<br/>le légataire à titre universel bénéficie d'un poucentage des biens légués le légataire particulier reçoit en héritage un bien spécifique et identifié de l'auteur du testament.</>,
      en:<>The universal legatee benefits from all bequeathed property <br /> the legatee on a universal basis benefits from a percentage of the bequeathed property the legatee receives in inheritance a specific and identified property from the author of the will.</>
    },
    bequestType:{
      en:'Type of bequest ',
      fr:'Type de Leg'
    },
    bequestCategory:{
      en:'Category of bequest ',
      fr:'Catégorie du Leg'
    },
    addBequest:{
      en:'Add bequest',
      fr:'Ajouter un Leg'
    },
    bequest:{
      en:'Bequest',
      fr:'Legs'
    },
    bequestInfo:{
      fr:'Legs : Un don par testament. Un legs peut être à titre particulier, à titre universel ou universel.',
      en:'Bequest: One donation per will. A bequest can be specific or universal.'
    },
    healthInsurance:{
      en:'Insurance number',fr:'Numéro d\'assurance maladie'
    },
    organTransplantation:{
      fr:"Uniquement pour les transplantation ",
      en:"Only for transplantation"
    },
    organScience:{
      fr:'don d\'organes et recherche scientifiques',
      en:'organ donation and scientific research'
    },
    organConsent:{
      en:"Do you consent to organ and tissue donation?",
      fr:"Consentez-vous au don d’organe et de tissus ? "
    },
    organInfo:{
      en:"For more information",
      fr:"Pour plus d'informations "
    },
    organInfoLink:{
      fr:"quebec.ca/sante/don-de-sang-de-tissus-et-d-organes/don-d-organes-et-de-tissus/",
      en:"https://www.quebec.ca/en/health/blood-tissue-and-organ-donation/organ-and-tissue-donation/"
    },
    undecided:{
      fr:'Indécis',en:'Undecided'
    },
    stubborness:{
      en:"Do you want to express your refusal in the face of any form of determination to extend your life - in the circumstances where there is no chance of recovering an acceptable quality of life?",
      fr:"Voulez-vous exprimer votre refus face à toute forme d’acharnement à prolonger votre vie, en circonstance où il n’existe pas de chance de récupérer une qualité de vie acceptable ?"
    },
    specialWishes:{
      en:"Organ donation",
      fr:"Don d\'organes"
    },
    Spouse_children:{
      en:'Spouse and children',
      fr:'Époux et enfants'
    },
    nobody:{
      en:'none of these options',
      fr:'aucun de ces choix'
    },
    select:{
      en:'Select',
      fr:'Sélectionnez'
    },
    familySecurity:{
      en:"I allow the use of my property for the benefit of",
      fr:"Je permet l'utilisation de mes biens au bénéfice de"
    },
    funeralCompany:{
      fr:'Avec quelle compagnie',
      en:'Which company'
    },
    funeralPrecision:{
      en:'How would you like your body to be handled?',
      fr:'Voulez-vous préciser vos volontées quant à la disposition de votre corps?'
    },
    funeralAllow:{
      fr:'Je laisse le soin de mes funérailles à la discrétion de mon liquidateur',
      en:'I allow my liquidator to organize my funeral'
    },
    heirBackupInfo:{
      fr:'Héritiers de remplacement',
      en:'Remplacement heir'
    },
    dativeInfo:{
      fr:'Une seule personne peut prendre la charge de vos enfants au moment de votre décès',
      en:'Only one person can be named to be the tutor'
    },
    etatCivilLink:{
      fr:'http://www.etatcivil.gouv.qc.ca/fr/FAQ.html',
      en:'http://www.etatcivil.gouv.qc.ca/en/FAQ.html'
    },
    dateContract:{
      fr:'Date du contrat',
      en:'Sign date'
    },
    since:{
      fr:'Depuis le',
      en:'since'
    },
    _dateFormat:{
      fr:"jj/mm/aaaa",
      en:"dd/mm/yyyy"
    },
    _dateFormat2:{
      fr:"mm/aaaa",
      en:"mm/yyyy"
    },
    funeralResponsible:{
      en:'The person/people responsible for my funeral:',
      fr:'Je laisse le soin de mes funérailles à la discrétion de :'
    },
    funeralSpouse:{
      en:'My spouse',
      fr:'Mon/Ma conjoint/e'
    },
    
    funeralChildren:{
      en:'My children',
      fr:'Mes enfants'
    },
    funeralLiquidator:{
      en:'Liquidator',
      fr:'Le liquidateur'
    },
    funeralDesire:{
      en:"I would like to be:",
      fr:"Je désire :"
    },
    cemeteryName:{
      en:'Name of cemetery',fr:'Nom du cimetière'
    },
    buried:{en:'Buried',fr:'Être inhumé (enterré)'},
    cremated:{en:'Cremated',fr:'Incinéré'},
    aquamated:{en:'Alkaline hydrolysis',fr:'Aquamation'},
    funeralCelebration:{
      en:'The ceremony will be celebrated in:',fr:'La cérémonie sera célébré dans:'
    },
    worshipPlace:{
      en:'A place of worship',fr:'Un lieu culte'
    },
    funeralHome:{
      en:'funeralHome',
      fr:'Maison funéraire'
    },
    liquidatorChoice:{
      en:'At the choice of the liquidator',
      fr:'Au choix du liquidateur'
    },
    funeralPlace:{
      fr:'Définissez le lieu de la cérémonie, laissez vide si identique au lieu de l\'enterrement',
      en:'Please specify where the ceremony will be held Leave this field empty if it is the same as the burying site '
    },
    funeralReligiousName:{
      fr:'Nom du lieu culte (paroise ou autre)',
      en:'Place of worship (parish or other)'
    },
    funeralCeremonyDetails:{
      fr:'Précisions au sujet de la cérémonie',
      en:'Details about the ceremony'
    },
    funeralExposition:{
      fr:'Je désire que mon corps soit exposé pendant combien de jours',
      en:'I would like the casket or urn to be exposed this many days',
    },
    funeralArrangments:{
      fr:'Avez-vous contracté un arrangement funéraire préalable ?',
      en:'Have you made a prior funeral arrangement?'
    },
    funeralContract:{
      fr:'Votre numéro de contrat',
      en:'Contract number'
    },
    withContract:{
      en:'With contract',
      fr:'Avec contrat'
    },
    withoutContract:{
      en:'Without contract',
      fr:'Sans contrat'
    },
    relationship:{
      en:'Relationship with you',
      fr:'Relation avec vous'
    },
    beforeWedding:{
      fr:'Au moment de notre mariage nous étions domicilié ailleurs',
      en:'At the time of our marriage, we were domiciled elsewhere'
    },
    dateOfBirth:{
      fr:'Date de naissance',
      en:'Date of birth'
    },
    inheritAge:{
      fr:'Âge éligible à la succession',
      en:'Age eligible for inheritance'
    },
    afterWillContact:{
      fr:'Contacter cette personne une fois le document officialisé',
      en:'Contact this person once the document has been formalized'
    },
    
    _district:{
      en:'District',
      fr:'District'
    },
    _name:{
      en:'Name',
      fr:'Nom'
    },
    _firstname:{
      en:'First name',
      fr:'Prénom'
    },
    _title:{
      en:'Sex',
      fr:'Sexe'
    },
    _sir:{
      en:'Sir',
      fr:'Monsieur'
    },
    _madam:{
      en:'Madam',
      fr:'Madame'
    },
    _phone:{
      en:'Phone',
      fr:'Téléphone'
    },
    _email:{
      en:'Email',
      fr:'Courriel'
    },
    _street:{
      en:'Street',
      fr:'Rue'
    },
    _street2:{
      en:'Address',
      fr:'Adresse'
    },
    _city:{
      en:'City',
      fr:'Ville'
    },
    _postal:{
      en:'Postal code',
      fr:'Code postal'
    },
    
    _province:{
      en:'Province',
      fr:'Province'
    },
    
    _country:{
      en:'Country',
      fr:'Pays'
    },
    _other:{
      fr:"Autre",
      en:"Other"
    },
    
    representativeBackup:{
      en:"Replacement",
      fr:"Remplaçant"
    },
    representativeAssets:{
      en:"Responsible for my assets",
      fr:"Responsable de mon patrimoine"
    },
    representativePerson:{
      en:"Responsible of my person",
      fr:"Responsable de ma personne"
    },
    divorceName:{
      en:'Name',
      fr:'Nom'
    },
    divorce:{
      en:'Divorced',
      fr:'Divorcé'
    },
    divorceFirstname:{
      en:'Firstname',
      fr:'Prénom'
    },
    dwellAt:{
      en:'Residing at',
      fr:'Réside à'
    },
    divorceCourt:{
      fr:'District de la cour supérieure',
      en:'Superior Court District'
    },
    divorceNumber:{
      fr:"# de dossier",
      en:'file number'
    },
    divorceDate:{
      fr:"Date du divorce",
      en:'Divorce date'
    },
    
    deathDate:{
      fr:'Date du décès',
      en:'Date of death'
    },
    married:{
      en:"Married",
      fr:"Marié"
    },
    divorceDocuments:{
      en:'We require all documents pertaining to your divorce during the appointment',
      fr:'Nous avons besoin de tous les documents prouvant votre divorce lors de votre appel'
    },
    commonLawSpouse:{
      en:"Common-law spouse",
      fr:"Conjoint de fait"
    },
    widow:{
      en:"widowed",
      fr:"Veuf/ve"
    },
    single:{
      en:"Single",
      fr:"Célibataire"
    },
    beenDivorced:{
      en:"Have you ever been divorced?",
      fr:"Avez-vous déjà divorcé par le passé ?"
    },
    mariedContractNotary:{
      fr:'Avez-vous signé un document devant notaire avant la célébration ?',
      en:"Have you signed a document in front of notary prior to the ceremony?"
    },
    maritalStatus:{
        fr:"État civil",
        en:"Marital status"
    },
    copyEvery:{
      fr:<>Copier ce choix pour <em>tous</em> les enfants.</>,
      en:<>Copy for all children</>
    },
    heir:{
      fr:"Héritiers",
      en:"Heirs"
    },
    representative:{
      fr:"Mandataires",
      en:"Mandatary"
    },
    mandate:{
      fr:"Mandat",
      en:"Mandate"
    },
    testateur:{
      fr:"Testateur",
      en:"Testator"
    },
    liquidator:{
      fr:"Liquidateur",
      en:"Liquidator"
    },
    weddingNumberFile:{
      fr:'Numéro de dossier',
      en:'File number'
    },
    weddingNumber:{
      fr:'Sous quel numéro de ses minutes ?',
      en:'Under which minute number? '
    },
    vieCommune:{
      fr:'Date de vie commune',
      en:'Date of common-law'
    },
    conjointStatusExplain:{
      fr:'Conjoint de fait (ne modifie pas votre état civil)',
      en:'Common-law partner (does not change your marital status)'
    },
    matrimonialRegime:{
      fr:'Sous quel régime matrimonial ?',
      en:'Under which matrimonial regime?'
    },
    none:{
      fr:"Aucun",
      en:"None"
    },
    other:{
      fr:"Autre",
      en:"Other"
    },
    goodsSplit:{
      fr:"séparation de biens",
      en:"Separation of property"
    },
    factualSeparation:{
      fr:"Séparé de fait",
      en:"De facto separated"
    },
    
    infoConjoint:{
      fr:'Information conjoint',
      en:"Information of common-law partner"
    },

    infoConjointDead:{
      fr:"Informations relatives au décès de votre conjoint",
      en:"Relevant information of common-law partner's death"
    },

    partnershipAcquest:{
      fr:"société d'acquêts",
      en:"Partnership of acquest"
    },
    law42:{
      en:"We signed an agreement which excludes the application of the rules relating to family patrimony under article 42 before the notary (see below).",
      fr:" Nous avons signé une convention excluant l’application des règles relatives au patrimoine familial en vertu de l’article 42 devant le notaire ci-bas."
    },
    description:{
      en:'Description',
      fr:'Description'
    },
    spouse:{
      fr:'Conjoint',
      en:'Spouse'
    },
    lastDivorce:{
      fr:'Prenant votre dernier divorce, identifiez votre ex-conjoint',
      en:'Please identify your recent ex-spouse'
    },
    weddingContract:{
      fr:"Nous avons besoin d'une copie de votre dernier contrat de mariage, veuillez nous l'envoyer par votre portail Neolegal.",
      en:"Please provide a copy of your last marriage contract through your Neolegal portal."
    },
    spouseLegacy:{
      fr:'Je désire léguer tous les biens dont je suis copropriétaire à mon/ma conjoint/e',
      en:"I wish to bequeath all property, of which I am co-owner, to my spouse."
    },
    define:{
      fr:"Précisez",
      en:"Define"
    },
    _define:{
      fr:"Précisez",
      en:"Specify"
    },
    minuteBooks:{
      fr:'livre des minutes',
      en:'minute books'
    },
    representativeWatcher:{
      fr:'Il devra rendre des comptes à quelqu\'un',
      en:'Hold someone accountable'
    },
    representativeWatcherTarget:{
      fr:'Personne à qui il devra des comptes',
      en:'Mandatary supervisor'
    },
    registrationDivision:{
      en:"Which registration division?",
      fr:'Quelle circonscription foncière ?'
    },
    currentRelation:{
      fr:"Vos informations",
      en:"Your informations"
    },
    weddingDate:{
      fr:"Date de la cérémonie",
      en:"Celebration date"
    },
    whichNotary:{
      en:'In front of which notary?',
      fr:'Devant quel notaire ?'
    },
    yes:{
      fr:"Oui",
      en:"Yes"
    },
    no:{
      fr:"Non",
      en:"No"
    },
    funeralDisposition:{
      fr:"Dispositions funéraires",
      en:"Funeral arrangment"
    },
    mentalIncapacity:{
      fr:"En cas d’inaptitude mentale",
      en:"Protection mandate in case of incapacity"
    },
    addMandatory:{
      fr:"Ajouter un mandataire",
      en:"Add mandatory"
    },
    mandatory:{
      fr:"Personne à qui est conféré un mandat",
      en:"The person responsible of you in case of incapacity"
    },
    addHeir:{
      fr:'Ajouter un héritier',
      en:'Add heir'
    },
    liquidatorExpense:{
      fr:"La somme reccomandée est de 1000$ à 10000$ selon l\'importance de votre patrimoine.",
      en:"The suggested sum is between $1,000 and $10,000, according to the value of patrimony."
    },
    
    liquidatorFrequency:{
      fr:"À l'aniverssaire du décès ou à chaque mois",
      en:"Annualy or every month"
    },
    testateurHelp:{
      fr:"Vous ou l'auteur du testament",
      en:"You or testator"
    },
    heirHelp:{
      fr:'Personne qui doit recevoir ou qui reçoit des biens en héritage.',
      en:'Person who is to receive or receives inheritance goods.'
    },
    liquidatorHelp:{
      fr:"Personne ayant pour mission de procéder à la liquidation d'une masse de biens.",
      en:'The person tasked to execute the liquidation.'
    },
    liquidatorMarital:{
      fr:"Mon conjoint ou ma conjointe est liquidataire.",
      en:"My husband or spouse is my liquidator."
    },
    maritalInfoLink:{
      fr:'http://www.etatcivil.gouv.qc.ca/fr/FAQ.html',
      en:'http://www.etatcivil.gouv.qc.ca/en/FAQ.html'
    },
    addLiquidator:{
      fr:"Ajouter un liquidateur de remplacement",
      en:"Add secondary liquidator"
    },
    liquidatorShareTask:{
      fr:"Les liquidataires se partageront les tâches.",
      en:"Liquidators will share equally all tasks."
    },
    willForm:{
      en:'Will form',
      fr:'Formulaire testament'
    },
    willmandateForm:{
      en:'Mandate form',
      fr:'Formulaire mandat'
    },
    fillAll:{
      fr:'S\'il vous plait veuillez renseigner tous ces champs',
      en:'Please fill this form completely.'
    },
    firstPick:{
      en:'First choice',
      fr:'Premier choix'
    },
    wrongKey:{
      fr:<em style={{color:"red"}}>Mauvaise clef de traduction</em>,
      en:<em style={{color:"red"}}>Wrong translation key</em>
    },
    _wrongKey:{
      fr:'Mauvaise clef de traduction',
      en:'Wrong translation key'
    },
    missing:{
      fr:<em style={{color:"red"}}>la traduction est manquante</em>,
      en:<em style={{color:"red"}}>missing translation</em>
    },
    placeholder:{
      fr:"Si connu, remplissez",
      en:"To fill, if known"},
    h1:{
      fr:"Formulaire incorporation",
      en:"Incorporation Form"},
    answerAll:{
      fr: 'Merci de répondre à toutes les questions ci-dessous',
      en:'Please answer all questions below'
    },
    fileslang:{
      en:'In which langage do you want the incorporation documents?',
      fr:'En quelle langue désirez-vous les documents d\'incorporation ?'
    },
    french:{en:'French',fr:'Français'},
    english:{en:'English',fr:'Anglais'},
    origin:{
      fr:'Origine et explication du nom de l\'entreprise',en:'Explaination and origin of company\'s name'
    },
    name:{
      fr:'Le nom de l’entreprise',en:'Name of the company'
    },
    restriction:{
      fr:'Décrivez les activités exploitées par la société et ses restrictions, le cas échéant.',
      en:'Describe the activities operated by the company and its restrictions, if any.'
    },
    maxAdmin:{
      fr:"Le nombre minimal et maximal d'administrateurs (minimum 1, nous reccomandons un maximum de 10)",
      en:"Minimum and maximum number of directors (Between 1 and 10)"
    },
    maxAdminSmall:{
      fr:"Nous vous suggérons un minimum de 1 et un maximum de 10, si applicable.",
      en:"We suggest a minimum of 1 and a maximum of 10, when applicable."
    },
    actionCategory:{
      fr:"Les catégories et le nombre maximal d'actions que la société est autorisée à émettre.",
      en:"The categories and the maximum number of shares that the company is authorized to issue."
    },
    actionCategorySmall:{
      fr:"Nous pouvons fournir la description standard de capital-actions, soit des actions ordinaires et les différentes catégories d'actions privilégiées",
      en:"We can provide the standard share capital description, in essence common shares and different classes of preferred shares."
    },
    admin:{
      fr:"Le nombre minimal et maximal d'administrateurs (nous vous suggérons un minimum de 1 et un maximum de 10, si applicable)",
      en:"Minimum and maximum number of directors (We suggest a minimum of 1 and a maximum of 10, si applicable"
    },
    address:{
      fr:"L'adresse du siège social de la société",
      en:"The address of the registered office of the corporation"
    },
    addressSmall:{
      fr:<br/>,
      en:<br/>
    },
    
    socialInsuranceNumber:{
      fr:'Numéro d\'assurance sociale',
      en:'Social insurance number'
    },
    postalAddress:{
      fr:"L'adresse postale de la société",
      en:"The mailing address of the company"
    },
    postalSmall:{
      fr:"Si différent de l’adresse du siège social",
      en:"If different from the registered office address"
    },
    postForm:{
      fr:"Suite à l'incorporation de l'entreprise, nous aurons besoin de l'organiser, notamment par la création du livre de minutes. À cet effet, veuillez nous fournir les informations suivantes :",
      en:"After incorporating, we will need to create the minute book. For this purpose, please provide the following information:"
    },
    shareHolders:{
      fr:"Le nom et l'adresse des actionnaires et le nombre (pourcentage) et la catégorie d’actions à souscrire par les actionnaires.",
      en:"The name and address of the shareholders, along with their respective amount and the category of shares."
    },
    shareHoldersSmall:{
      fr:"Généralement cent actions de classe A pour une société de portefeuille",
      en:"There are usually one hundred Class A shares class for a holding company."
    },
    actionPrice:{
      fr:"Le prix à payer par chaque actionnaire pour la souscription d'actions",
      en:"The price to be paid by each shareholder for the subscription of shares"
    },
    actionPriceSmall:{
      fr:"Généralement un dollar par action pour une société de portefeuille",
      en:"There is generally one dollar per share for a holding company."
    },
    date:{
      fr:"La date de la fin de l'année financière de la société.",
      en:"The financial year end date of the corporation."
    },
    adminInfo:{
      fr:"Le nom et l'adresse des administrateurs de la société",
      en:"The name and address of the directors of the corporation"
    },
    adminInfoSmall:{
      en:"if not the same as mentioned in the incorporation",
      fr:"Si cela diffère de celle(s) mentionnée(s) au moment de la constitution)"
    },
    importantPersonInfo:{
      en:"The name and address of the officers of the corporation",
      fr:"Le nom et l'adresse des dirigeants de la société "
    },
    importantPersonInfoSmall:{
      en:"President, Vice President, CEO, CFO, Secretary, etc.",
      fr:"Président, le vice-président, CEO, CFO, secrétaire, etc"
    },
    audit:{
      fr:"Le nom et l'adresse des auditeurs/comptables de la société",
      en:"The name and address of the auditors of the corporation"
    },
    auditSmall:{
      fr:"Si aucun n’est nommé, le nom et l'adresse du comptable",
      en:"If no auditors are appointed, the name and address of the accountant"
    },
    employees:{
      en:"The number of employees of the corporation, if any",
      fr:"Le nombre d'employés de la société"
    },
    hq:{
      en:'If the registered office of the corporation is not in Québec, but the company has a location in Quebec, please specify the address.',
      fr:"L'adresse de l’entreprise au Québec"
    },
    
    hqSmall:{
      en:<span style={{color:"red"}}>**If the registered office of the corporation is not in Québec**</span>,
      fr:<span style={{color:"red"}}>**Si le siège social de l'entreprise n'est pas au Québec**</span>
    },
    otherName:{
      fr:"Autres noms de la société qui peut être utilisé au Québec",
      en:"Alternative company names"
    },
    submit:{
      fr:'Soumettre',
      en:'Submit'
    },
    otherLang:{
      fr:'EN',
      en:'FR'
    },
    
    tutor:{
      fr:'Tuteurs',
      en:'Tutors'
    },
    tutorNoS:{
      fr:'Tuteur',
      en:'Tutor'
    },
    principal:{
      fr:'Mandant',
      en:'Principal'
    },
    tutorForChildren:{
      fr:'Tuteurs des enfants',
      en:'Tutor for your children'
    },
    
    addTutor:{
      fr:'Ajouter un tuteur de remplacement',
      en:'Add a backup tutor'
    },
    
    child:{
      en:'Child',
      fr:'Enfant'
    },
    children:{
      en:'Your children',
      fr:'Vos enfants'
    },
    
    addChildren:{
      en:'Add children',
      fr:'Ajouter un enfant'
    },
    minorHeir:{
      en:"Children",
      fr:'L\'enfant'
    },
    wealth:{
      en:"Wealth",
      fr:'Fortune'
    },
    both:{
      en:'Both',
      fr:'Les deux'
    },
    childrenMinor:{
      en:'Only children of minor age',
      fr:'Seulement les enfants d\'âge mineur'
    },
    _certificatDate:{
      en: "Date of Certificate",
      fr: "Date de Certificat"
    },
    _certificatNumber:{
      en: "Certificat Number",
      fr: "Numéro de Certificat"
    },
    _actionNumber:{
      en: "Actions Number",
      fr: "Nombre d'actions"
    },
    _serialNumber:{
      en: "Serial number",
      fr: "Numéro de série"
    },
    _categories:{
      en: "Categorie of certificat",
      fr: "Catégorie de la certificat"
    },

    _certificatList:{
      en: "Those represented by this certificate are:",
      fr: "Les représentées par ce certificat sont :"
    },

    _declarationList:{
      en: "Declaration",
      fr: "Déclaration"
    },

    _certificatInfos:{
      en: "Certificat Informations",
      fr: "Informations de la certificat"
    },

    _citizen:{
      en: "Canadian citizen",
      fr: "Citoyen canadien"
    },

    _permanentResident:{
      en: "Permanent resident",
      fr: "Résident permanent"
    },

    _notResident:{
      en: "Not resident",
      fr: "Non résident"
    },

    _startdate:{
      en: "Registration date",
      fr: "Date d'enregistrement"
    },

    _enddate:{
      en: "End of registration",
      fr: "Fin d'enregistrement"
    },






    zqwnamecny_x:{
      en: "Company Name",
      fr: "Nom de Compagnie"
    },
    _language:{
      en: "Language",
      fr: "Langue"
    },
    zqwend_financial_year_x:{
      en: "End financial year",
      fr: "Fin année fiscal"
    },
    zqwjurisdiction_x:{
      en: "Jurisdiction",
      fr: "Juridiction"
    },
    zqwcity_x:{
      en: "City",
      fr: "Ville"
    },
    zqwprovince_x:{
      en: "Province",
      fr: "Province"
    },
    zqwcp_x:{
      en: "Postal Code",
      fr: "Code Postal"
    },
    zqwaddress_x:{
      en: "Address",
      fr: "Adresse"
    },
    _Autre:{
      en:'Other',
      fr:'Autre'
    },
    _neomed:{
      en: "Please chose a template when it applies, if there is no template, please select if template doesn't exist.",
      fr: "Prière de choisir le modèles de mise en demeure applicable, et si celui-ci n'est pas encore disponible ou créé, merci de choisir modèle de mise en demeure non existant."
    },
    _domain:{
      en: "Domain",
      fr: "Domaine"
    },
    _subdomain:{
      en: "Sub-Domain",
      fr: "Sous-Domaine"
    },
    _modele:{
      en: "Model",
      fr: "Modèle"
    },
    _btncreer:{
      en: "Create",
      fr: "Créer"
    },
    _btnsearch:{
      en: "Search",
      fr: "Recherche"
    },
    _actdoclbl:{
      en: "Add one of the following documents (driver's license, health insurance card or passport)",
      fr: "Ajouter l'un des documents suivants (permis de conduire, carte d’assurance maladie ou passeport)"
    },
    _btnadd:{
      en: "Add",
      fr: "Ajouter"
    },
    Ajouter:{
      en: "Add",
      fr: "Ajouter"
    },
    _sample002:{
      en: "Registrationdate",
      fr: "Dateenregistrement"
    },
    _adminSign:{
      en:"Signatory",
      fr:"Signataire"
    },
    neoleads: {
      timelineLeadReturn: {
        en: 'Return to Leads',
        fr: 'Retourner au Leads'
      },
      timelineLeadNextLead: {
        en: 'Next Lead',
        fr: 'Prochain Lead'
      },
      sale: {
        en: 'Sale',
        fr: 'Vente'
      },
      noSale: {
        en: 'No Sale',
        fr: 'Non Vente'
      },
      reminder: {
        en: 'Reminder',
        fr: 'Rappel'
      },
      reminderTooltip: {
        en: 'Open Calendar',
        fr: 'Ouvrir Calendrier'
      },
      register: {
        en: 'Submit',
        fr: 'Enregistrer'
      },
      search: {
        en: 'Search',
        fr: 'Recherche'
      },
      loading: {
        en: 'Loading...',
        fr: 'En Cours...'
      },
      calendar: {
        en: 'Calendar',
        fr: 'Calendrier'
      },
      clientInfo: {
        en: 'Client Info',
        fr: 'Info Client'
      },
      contactInfo: {
        en: 'Contact Info',
        fr: 'Info Contact'
      },
      tasks: {
        en: 'Tasks',
        fr: 'Tâches'
      },
      nextCalls: {
        en: 'My next calls',
        fr: 'Mes prochains appels'
      },
      lastName: {
        en: 'Last Name',
        fr: 'Nom'
      },
      email: {
        en: 'Email',
        fr: 'Courriel'
      },
      phone: {
        en: 'Phone',
        fr: 'Téléphone'
      },      
      firstName: {
        en: 'First Name',
        fr: 'Prénom'
      },
      clientPurchases: {
        en: 'Client Purchases',
        fr: 'Achats de Client'
      },
      noPurchases: {
        en: 'No Purchases',
        fr: 'Aucun Achats'
      },
      contactCreation: {
        en: 'Contact Creation',
        fr: "Création d'un contact"
      },
      erase: {
        en: 'Clear',
        fr: 'Effacer'
      },
      createContact : {
        en: "Create a Contact",
        fr: "Créer un Contact  "
      },
      noContactResults : {
        en: 'No results found, create a contact?', 
        fr: 'Aucun résultat trouvé, créer un contact?'
      },
      subject : {
        en: 'Subject',
        fr: 'Sujet'
      },
      domain: {
        en: "Domain",
        fr: "Domaine"
      },
      language: {
        en: "Language",
        fr: "Langue"
      },
      cancel: {
        en: 'Cancel',
        fr: 'Annuler'
      },
      editContact: {
        en: "Edit Contact",
        fr: "Modifier Contact"
      },
      createLead: {
        en: "Create a Lead",
        fr: "Créer un Lead"
      },
      existingContacts: {
        en: "Existing Contacts",
        fr: "Contacts Existants"
      },
      getLeads: {
        en: "Get Leads",
        fr: "Obtenir les Leads"
      },
      editContact: {
        en: "Edit Contact Info",
        fr: "Modifier Info de Contact"
      },
      createContactWithClient: {
        en: "Create Contact with this Client", 
        fr: 'Creer Contact avec ce Client'
      },
      english: {
        en: "English",
        fr: "Anglais"
      },
      french: {
        en: "French",
        fr: "Français"
      },
      noLeads: {
        en: "No Leads",
        fr: "Aucun Leads"
      },
      validationError: {
        en: "Please fill out all fields",
        fr: "Veuillez remplir tous les champs"
      },
      numberOfLeads: {
        en: "Number of Leads:",
        fr: "Nombre de Leads:"
      },
      numberOfReminders: {
        en: "Number of Reminders:",
        fr: "Nombre de Rappels:"
      },
      downloading: {
        en: "Loading",
        fr: "Chargement en cours"
      },
      reminders: {
        en: "Reminders",
        fr: "Rappels"
      },
      status: {
        en: "Status",
        fr: "Statut"
      },
      created: {
        en: "Created",
        fr: "Créé"
      },
      edit: {
        en: "Edit",
        fr: "Modifier"
      },
      viewLead: {
        en: "View Lead",
        fr: "Voir Lead"
      },
      editLead: {
        en: "Edit Lead",
        fr: "Modifier Lead"
      },
      close: {
        en: "Close",
        fr: "Fermer"
      },
      postError: {
        en: "Error, couldn't process request",
        fr: "Erreur, impossible de traiter la demande"
      },
      automaticDistrubution: {
        en: "Automatic Distribution",
        fr: "Distribution Automatique"
      },
      ecommercesms: {
        en: "Ecommerce SMS",
        fr: "Ecommerce SMS"
      },
      notAutomaticDistribution: {
        en: "Not sent to Automatic Distribution",
        fr: "Pas envoyer à Distribution Automatique"
      },
      supported: {
        en: "Supported",
        fr: "Pris en charge"
      },
      attributed: {
        en: "Attributed",
        fr: "Attribué"
      },
      closed: {
        en: "Closed",
        fr: "Fermé"
      },
      cancelled: {
        en: "Cancelled",
        fr: "Eliminé"
      },
      languages : {
        en: 'Language(s)',
        fr: 'Langue(s)'
      },
      domains: {
        en: "Domain(s)",
        fr: "Domaine(s)"
      },
      editUser: {
        en: "Edit User",
        fr: "Modifier User"
      },
      myNextLeads: {
        en: "My Next Leads",
        fr: "Mes Prochains Leads"
      },
      origin: {
        en: "Origin",
        fr: "Origine"
      },
      attribute: {
        en: "Attribute",
        fr: "Attribut"
      },
      cancelLead: {
        en: "Cancel Lead",
        fr: "Annuler Lead"
      },
      cold: {
        en: "Cold",
        fr: "Froid"
      },
      warm: {
        en: "Medium",
        fr: "Moyen"
      },
      hot: {
        en: "Hot",
        fr: "Chaud"
      },
      priority: {
        en: "Priority",
        fr: "Priorité"
      },
      edit: {
        en: "Edit",
        fr: "Modifier"
      },
      leadInfo: {
        en: "Lead Info",
        fr: "Info Lead"
      },
      message: {
        en: "Message",
        fr: "Message"
      },
      leadHistory: {
        en: "History of Leads",
        fr: "Historique des Leads"
      },
      oldToNew : {
        en: "Old to New",
        fr: "Vieux à Récent"
      },
      newToOld: {
        en: "New to Old",
        fr : "Récent à Vieux "
      },
      recencyPriority: {
        en: "Recency Priority",
        fr: "Priorité de Récence"
      },
      thermoPriority: {
        en: "Thermo Priority",
        fr: "Priorité de Thermos"
      },
      rappelCreated: {
        en: "Reminder created, this lead is temporarily closed, please process the next lead.",
        fr: "Rappel créé, ce lead est temporairement fermé, veuillez traiter le lead suivant."
      },
      ticketNumberZendesk : {
        en: "Ticket # (Zendesk)",
        fr: '# Ticket (Zendesk)'
      },
      closeLead: {
        en: "Close Lead",
        fr: "Fermer Lead"
      },
      leadId: {
        en: "Lead ID",
        fr: "Lead ID"
      },
      legacyBackup:{
        en:'Other',
        fr:'Autre'
      },
      showNotes: {
        en: "Show Notes",
        fr: "Afficher les Notes"
      },
      leadHistoryNotes: {
        en: "Notes History",
        fr: "Historique des Notes"
      },
      open: {
        en: "Open",
        fr: "Ouvrir"
      },
      filter: {
        en: "Filter",
        fr: "Filtre"
      },
      refresh: {
        en: "Refresh",
        fr: "Rafraîchir"
      },
      transferLead: {
        en: "Transfer Lead to another Agent",
        fr: "Transférer le Lead à un autre Agent"
      },
      transferLeadButton: {
        en: "Transfer Lead",
        fr: "Transférer Lead "
      },
      empty: {
        en: "Clear",
        fr: "Vider"
      },
      mySalesStats: {
        en: "My Sales Stats (Today)",
        fr: "Mes Statistiques de Vente (Aujourd'hui)"
      },
      mySales: {
        en: "My Sales",
        fr: "Mes Ventes"
      },
      myRecord: {
        en: "My Record",
        fr: "Mon Record"
      },
      mySalesDollar: {
        en: "My Sales ($)",
        fr: "Mes Ventes ($)"
      },
      myRecordDollar: {
        en: "My Record ($)",
        fr: "Mon Record ($)"
      },
      leadClosedMessage: {
        en: "This Lead is closed, click 'Take Lead' to open it.",
        fr: "Ce Lead est fermé, veuillez cliquer sur 'Prendre en Charge' pour l'ouvrir."
      },
      takeLead: {
        en: "Take Lead",
        fr: "Prendre en Charge"
      },
      fillOutAllFields: {
        en: "Please fill out required fields",
        fr: "Veuillez remplir toutes les données"
      },
      agent: {
        en: "Agent",
        fr: "Agent"
      },
      leadNotAssigned: {
        en: "This Lead is not assigned to you. To take the lead click on 'Take Lead'",
        fr: "Ce Lead n'est pas attribué à vous. Pour prendre le Lead, cliquez sur 'Prendre en Charge'"
      },
      editMacro: {
        en: "Edit Macros",
        fr: "Modifications des macros"
      },
      macroPostSuccess: {
        en: "Macro updated succesfully",
        fr: "Macro mise à jour avec succès"
      },
      createNewMacro: {
        en: "Create new macro",
        fr: "Créer une nouvelle macro"
      },
      dontDeleteBrackets: {
        en: "Please don't delete the fields between brackets {{ }}",
        fr: "Veuillez ne pas supprimer les champs qui sont entre accolades {{ }}"
      },
      select:{
        en:'Select',
        fr:'Sélectionnez'
      },
      clear: {
        en: "Clear",
        fr: "Vider"
      },
      title: {
        en: "Title",
        fr: "Titre"
      },
      privateNote: {
        en: "Private Note",
        fr: "Note privée"
      },
      publicNote: {
        en: "Public Note",
        fr: "Note publique"
      },
      deactivateMacro: {
        en: "Deactivate macro",
        fr: "Désactiver la macro"
      },
      confirmDeactivateMacro: {
        en: "Are you sure you want to deactivate macro?",
        fr: "Êtes-vous sûr de vouloir désactiver la macro ?"
      },
      confirm: {
        en: "Confirm",
        fr: "Confirmer"
      },
      addFile: {
        en: "Add a file",
        fr: "Ajouter un fichier"
      },
      originFolder: {
        en: "Origin folder",
        fr: "Dossier d'origine"
      },
      destinationFolder: {
        en: "Destination folder",
        fr: "Dossier de destination"
      },
      confirmMergeTickets: {
        en: "Merge file {{fromTicketId}} in file {{toTicketId}}?",
        fr: "Fusionner le dossier {{fromTicketId}} dans le dossier {{toTicketId}}?"
      },
      actionIsIrreversible: {
        en: "This action is irreversible!",
        fr: "Cette action est irréversible!"
      },
      mergeFiles : {
        en: "Merge files",
        fr: "Fusion des dossiers"
      },
      typeToSearch: {
        en: "Type to search...",
        fr: "Tapez pour rechercher..."
      },
      results: {
        en: "results",
        fr: "résultats"
      },
      select: {
        en: "Select...",
        fr: "Sélectionner..."
      },
      priorityMessage: {
        en: "Call/Email/SMS inbound from client",
        fr: "Appel/E-mail/SMS entrant du client"
      },
      fetchErrorProducts: {
        en: "Error in fetching products.",
        fr: "Erreur lors de la récupération des produits."
      },
      generatedDocs: {
        en: "Generated Documents",
        fr: "Documents générés",
      },
      noGeneratedDocs: {
        en: "No documents generated for this folder.",
        fr: "Aucun document généré pour ce dossier."
      },
      download: {
        en: "Download",
        fr: "Télécharger"
      },
      preview: {
        en: "Preview",
        fr: "Aperçu"
      },
      openInPSJ: {
        en: "Open in PSJ",
        fr: "Ouvrir dans le PSJ"
      },
      changeAssign: {
        en: "Change PSJ assignation",
        fr: "Changer l'assignation",
      },
      inopportun: {
        en: "Inopportun",
        fr: "Inopportun"
      }

    },
  
  }
  
export default trad;