import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Row,
  Col,
  Label,
  Nav,
  Input,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  Spinner,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faPhone,
  faEnvelope,
  faUserCircle,
  faEraser,
  faSearch,
  faRedo,
  faInfoCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import moment from "moment";
import _, { rest, update } from "lodash";
import Table from "../../../utils/MaterialTable";
import ActionBar from "./ActionBar";
import EditLeadModal from "./EditLeadModal";
import PriorityIconsToggle from "./PriorityIconsToggle";
import axios from "axios";
import Loader from "../../../../../loader";
import AlertIconsToggle from "./AlertIconsToggle";
import IconButton from "../../../IconButton/IconButton";
import { DownloadCSVButton } from "./DownloadCSVButton";
import { axiosDefaults } from "../../../../../appConfigaxios";
const BACKEND1 = process.env.REACT_APP_API_URLX;

export default class CenterSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      rappels: [],
      showEditLead: false,
      error: false,
      loading: false,
      agentOptions: [],
      domaineOptions: [],
      macrosOptions: [],
      selectedLead: {
        id: "",
        domaineLead: { label: "" },
        created: "",
        updated_at: "",
        ticket_id: "",
        user: "",
        agentId: "",
        status: "",
        id_client: "",
        contact: "",
        definition: "",
        fromtrack: "",
        id_contact: "",
        lead_type: "",
      },
      filterAgent: "",
      filterStatus: "",
      filterDomaine: "",
      filterProvince: "",
      filterThermo: "",
      filterOther: "",
      filterOtherText: "",
      filterLoading: false,
      selectedCheckboxes: [],
      dataRawList: { neoleads: [] },
      fieldName: "",
      fieldName2: "",
      fieldVal: "",
      isLoaderOpen: false, //toastrMessage: '', toastrType: '', toastrDuration: 6000,
    };
  }

  componentDidMount() {
    axiosDefaults(null);
    this.fetchData();
    this.fetchMacros();
    this.fetchRappels();
    this.interval = setInterval(this.fetchFilterData, 100000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchRappels = () => {
    axios
      .get(BACKEND1 + "/leads/leads", {
        params: {
          //src: "desk",
          eventcal: "yes",
          showtrans: 3,
        },
      })
      .then(({ data }) => {
        const domaineOptions = data.data.domainList.map((i) => {
          return {
            label: i.label_fr,
            value: i.label_fr,
            id: i.id,
            name: i.name,
          };
        });
        const rappels = data.data.neoleads.map((i) => {
          const dom = () => {
            try {
              return domaineOptions.find((j) => j.name === i.domaine).value;
            } catch {
              return null;
            }
          };
          return {
            ...i,
            // domain: i.domaine,
            definition: JSON.parse(i.definition),
            domaineLead: dom(),
            user: i.agent,
            agentId: i.id_user,
          };
        });
        // // console.log(rappels)
        this.setState({ rappels: rappels });
      });
  };

  fetchMacros = () => {
    axios
      .get(BACKEND1 + "/leads/leads", {
        params: {
          onlyMacros: "yes",
          showtrans: 3,
        },
      })
      .then(({ data }) => {
        console.log(data.data.macros);
        const macrosOptions = data.data.macros
          .filter((i) => i.type !== "leadReason")
          .map((i) => {
            return { label: i.title, value: i.id, text: i.value };
          });
        // // console.log(rappels)
        this.setState({ macrosOptions: macrosOptions });
      });
  };

  fetchFilterData = () => {
    this.setState({ filterLoading: true });
    let {
      filterAgent,
      filterStatus,
      filterDomaine,
      filterOther,
      filterOtherText,
      filterProvince,
      filterThermo,
    } = this.state;
    let params = { showtrans: 3 };
    if (filterAgent) params.userId = filterAgent;
    if (filterStatus) params.status = filterStatus;
    if (filterDomaine) params.domaine = filterDomaine;
    if (filterOther && filterOtherText) {
      params.fieldName = filterOther;
      params.fieldVal = filterOtherText;
    }
    if (filterProvince) params.province = filterProvince;
    if (filterThermo) params.thermo = filterThermo;
    axios
      .get(BACKEND1 + "/leads/leads", {
        params,
      })
      .then((status) => {
        let { data } = status.data;
        this.setState({ filterLoading: false });
        if (data && data.neoleads) {
          let { neoleads } = data;
          const domaineOptions = data.domainList.map((i) => {
            return {
              label: i.label_fr,
              value: i.label_fr,
              id: i.id,
              name: i.name,
            };
          });
          const agentOptions = data.agentList.map((i) => {
            return { label: i.label, value: i.label, agentId: i.value };
          });
          let arr = _.map(neoleads, (e, i) => {
            const dom = () => {
              try {
                return domaineOptions.find((i) => i.name === e.domaine).value;
              } catch {
                return null;
              }
            };
            let subject = "Pas de sujet/No subject";
            if (e.definition !== null && e.definition !== undefined) {
              let myObj = undefined;
              if (e.definition.via !== undefined) {
                myObj = JSON.parse(JSON.stringify(e.definition));
                e.definition = JSON.stringify(e.definition);
              } else {
                myObj = JSON.parse(e.definition);
              }
              if (
                myObj !== undefined &&
                myObj !== null &&
                myObj.ticket_subject !== undefined &&
                myObj.ticket_subject !== null
              ) {
                subject = myObj.ticket_subject;
              }
            }
            const { domaine, agent, id_user, definition, ...rest } = e;
            return {
              ...rest,
              domaineLead: dom(),
              updated_at: moment().format("LLL"),
              user: e.agent,
              agentId: e.id_user,
              definition: JSON.parse(e.definition),
              subject: subject,
            };
          });

          this.setState({
            data: arr,
            domaineOptions: domaineOptions,
            agentOptions: agentOptions,
          });
        }
      });
  };

  fetchData = (obj) => {
    const fieldName = this.state.fieldName;
    const fieldVal = this.state.fieldVal;
    const leadstatus = this.state.leadstatus;
    let actionName = "";
    let params = {
      showtrans: 3,
    };

    if (obj !== undefined && obj !== null) {
      actionName = obj.objectName;
    }
    if (actionName !== undefined && actionName !== null && actionName !== "") {
      params = {
        showtrans: 3,
        page: obj.page,
        fieldName: obj.fieldName,
        fieldVal: obj.fieldVal,
      };
    } else {
      if (
        fieldName !== null &&
        fieldName !== "" &&
        fieldVal !== null &&
        fieldVal !== ""
      ) {
        params = {
          //src: "desk",
          showtrans: 3,
          page: 1,
          fieldName: fieldName,
          fieldVal: fieldVal,
        };
      }
    }
    axios
      .get(BACKEND1 + "/leads/leads", {
        params: params,
      })
      .then(
        (status) => {
          let { data } = status.data;
          //const dataAll= this.state.dataRawList;
          if (data && data.neoleads) {
            let { neoleads } = data;
            if (
              actionName !== undefined &&
              actionName !== null &&
              actionName === "loadNextPage"
            ) {
              const dataAll = this.state.dataRawList;
              dataAll.neoleads.push(...data.neoleads);
              //// console.log("222-POSTACTION data ", data, response.NeoVentes.data);
              dataAll.currentPage = parseInt(data.currentPage);
              //this.setState({ ["isLoaderOpen"]: false, ["NeoVentes"]: response.NeoVentes});
              this.setState({ ["dataRawList"]: dataAll });
              neoleads = dataAll.neoleads;
            } else if (
              actionName !== undefined &&
              actionName !== null &&
              actionName === "loadNextPageRedata"
            ) {
              this.setState({ ["dataRawList"]: data });
            } else if (
              actionName !== undefined &&
              actionName !== null &&
              actionName === "loadNextPageReset"
            ) {
              this.setState({ ["dataRawList"]: data });
            } else {
              this.setState({ ["dataRawList"]: data });
            }
            const domaineOptions = data.domainList.map((i) => {
              return {
                label: i.label_fr,
                value: i.label_fr,
                id: i.id,
                name: i.name,
              };
            });
            const agentOptions = data.agentList.map((i) => {
              return { label: i.label, value: i.label, agentId: i.value };
            });
            let arr = _.map(neoleads, (e, i) => {
              const dom = () => {
                try {
                  return domaineOptions.find((i) => i.name === e.domaine).value;
                } catch {
                  return null;
                }
              };
              let subject = "Pas de sujet/No subject";
              if (e.definition !== null && e.definition !== undefined) {
                let myObj = undefined;
                if (e.definition.via !== undefined) {
                  //myObj = e.definition;
                  myObj = JSON.parse(JSON.stringify(e.definition));
                  e.definition = JSON.stringify(e.definition);
                } else {
                  myObj = JSON.parse(e.definition);
                }
                if (
                  myObj !== undefined &&
                  myObj !== null &&
                  myObj.ticket_subject !== undefined &&
                  myObj.ticket_subject !== null
                ) {
                  subject = myObj.ticket_subject;
                }
              }
              const { domaine, agent, id_user, definition, ...rest } = e;
              return {
                ...rest,
                domaineLead: dom(),
                updated_at: moment().format("LLL"),
                user: e.agent,
                agentId: e.id_user,
                definition: JSON.parse(e.definition),
                subject: subject,
              };
            });

            const dataAll = this.state.dataRawList;
            dataAll.neoleads = arr;
            this.setState({
              data: arr,
              dataRawList: JSON.parse(JSON.stringify(arr)),
              dataRawList: JSON.parse(JSON.stringify(dataAll)),
              domaineOptions: domaineOptions,
              agentOptions: agentOptions,
            });
            this.setState({ ["isLoaderOpen"]: false });
          }
        },
        (error) => {
          // CHANGE BACK IN PROD
          this.setState({ ["isLoaderOpen"]: false });
          this.setState({
            redirect: "/login",
          });
        }
      );
  };

  handleCheckboxChange = (id) => {
    if (this.state.selectedCheckboxes.includes(id)) {
      const newSelectedCheckboxes = this.state.selectedCheckboxes.filter(
        (i) => i !== id
      );
      this.setState({ selectedCheckboxes: newSelectedCheckboxes }, () =>
        console.log(this.state.selectedCheckboxes)
      );
    } else {
      const newSelectedCheckboxes = [...this.state.selectedCheckboxes, id];
      this.setState({ selectedCheckboxes: newSelectedCheckboxes }, () =>
        console.log(this.state.selectedCheckboxes)
      );
    }
  };

  handleClearCheckboxes = () => {
    this.setState({ selectedCheckboxes: [] });
  };

  handleSelectAllCheckboxes = (e, rows) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      const newCheckboxes = rows.map((i) => i.id);
      this.setState({ selectedCheckboxes: newCheckboxes });
    } else {
      this.setState({ selectedCheckboxes: [] });
    }
  };

  handleSelectChange = (e, field) => {
    if (field === "agent") {
      this.props.tableView === "rappel"
        ? this.setState((prevState) => {
            return {
              ...prevState,
              selectedLead: {
                ...prevState.selectedLead,
                status: "1",
                user: e.label,
                agentId: e.agentId,
              },
            };
          })
        : this.setState((prevState) => {
            return {
              ...prevState,
              selectedLead: {
                ...prevState.selectedLead,
                user: e.label,
                agentId: e.agentId,
              },
            };
          });
    } else if (field === "thermo") {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedLead: { ...prevState.selectedLead, thermo: e.value },
        };
      });
    } else if (field === "language") {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedLead: { ...prevState.selectedLead, language: e.value },
        };
      });
    } else if (field === "province") {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedLead: { ...prevState.selectedLead, province: e.value },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedLead: { ...prevState.selectedLead, domaineLead: e.value },
        };
      });
    }
  };

  handleSelectActionChange = (e) => {
    const { agentOptions, selectedLead } = this.state;
    let currentAgent = agentOptions.find(
      (i) => i?.agentId == selectedLead?.agentId
    );
    let newLead = { ...selectedLead, status: e.value };
    if (e.value === "1") {
      newLead = {
        ...newLead,
        user: currentAgent?.label,
        agentId: currentAgent?.agentId,
      };
    }
    this.setState({ selectedLead: newLead });
  };

  handleSetValueChange = (e, field) => {
    console.log("parent-handleSetValueChange", field, e);
    if (field && field !== undefined && field !== null) {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedLead: { ...prevState.selectedLead, ["" + field]: e.value },
        };
      });
    }
  };

  handleSubmit = () => {
    const { selectedLead, domaineOptions } = this.state;
    const found = this.state.data.find((i) => i.id === selectedLead.id);
    const province = selectedLead.province; //this.state.province;
    const language = selectedLead.language; //this.state.language;
    const duedate = selectedLead.duedate;
    const followup = selectedLead.followup; // yes/no
    const isSame2 =
      (province === undefined || province === null || province === "") &&
      (language === undefined || language === null || language === "");
    const isSame3 =
      (duedate === undefined || duedate === null || duedate === "") &&
      (followup === undefined || followup === null || followup === "");
    const isSame =
      selectedLead.domaineLead === found.domaineLead &&
      selectedLead.status === found.status &&
      selectedLead.thermo === found.thermo &&
      selectedLead.agentId === found.agentId;
    console.log("SAME ? ", isSame);
    if (isSame === false || isSame2 === false || isSame3 === false) {
      this.setState({ loading: true });
      const domaine =
        selectedLead.domaineLead === null
          ? null
          : domaineOptions.find((i) => i.label === selectedLead.domaineLead)
              .name;
      const id =
        selectedLead.status === "0" || selectedLead.status === "5"
          ? "-1"
          : selectedLead.agentId;
      const agent =
        selectedLead.status === "0" || selectedLead.status === "5"
          ? "-1"
          : selectedLead.user;
      let obj = {
        ...selectedLead,
        agent: agent,
        id_user: id,
        domaine: domaine,
        setthermo: "yes",
      };
      if (province !== undefined && province !== null && province !== "") {
        obj.province = province;
      }
      if (language !== undefined && language !== null && language !== "") {
        obj.language = language;
      }
      if (followup !== undefined && followup !== null && followup !== "") {
        obj.followup = followup;
      }
      if (duedate !== undefined && duedate !== null && duedate !== "") {
        obj.duedate = duedate;
      }
      const {
        domaineLead,
        user,
        agentId,
        editIcon,
        tableStatus,
        priorityIcons,
        alertIcons,
        subjectHover,
        ...rest
      } = obj;
      console.log(rest);
      axios
        .post(BACKEND1 + "/leads/leads/lead", rest, {
          params: {},
        })
        .then((res) => {
          if (res.status === 200) {
            const user = agent === "-1" ? null : selectedLead.user;
            const agentId = id === "-1" ? null : selectedLead.agentId;
            this.setState({
              data: this.state.data.map((i) =>
                i.id === this.state.selectedLead.id
                  ? { ...selectedLead, user: user, agentId: agentId }
                  : { ...i }
              ),
              rappels: this.state.rappels.map((i) =>
                i.id === this.state.selectedLead.id
                  ? { ...selectedLead, user: user, agentId: agentId }
                  : { ...i }
              ),
              loading: false,
            });
            this.closeChangeLeadModal();
            this.fetchFilterData();
          } else {
            this.setState((prevState) => {
              return { ...prevState, error: true, loading: false };
            });
          }
        });
    }
  };

  handleTableStatus = (lead) => {
    switch (lead.status) {
      case "0":
        return this.genLang("automaticDistrubution");
      case "1":
        return this.genLang("attributed");
      case "2":
        return this.genLang("supported");
      case "3":
        return this.genLang("closed");
      case "4":
        return this.genLang("notAutomaticDistribution");
      case "5":
        return this.genLang("cancelled");
      case "7":
        return this.genLang("inopportun");
      case "8":
        return this.genLang("ecommercesms");
    }
  };

  openChangeLeadModal = (clickedLead) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        showEditLead: true,
        selectedLead: { ...clickedLead },
      };
    });
  };

  handleChangePriority = (id, priority) => {
    axios
      .post(
        BACKEND1 + "/leads/leads/lead",
        {
          setthermo: "yes",
          thermo: priority,
          id: id,
        },
        {
          params: {},
        }
      )
      .then((res) => {
        const { record } = res.data.data;
        if (record) {
          const dom = () => {
            try {
              return this.state.domaineOptions.find(
                (i) => i.name === record.domaine
              ).value;
            } catch {
              return null;
            }
          };
          let subject;
          if (record.definition !== null && record.definition !== undefined)
            subject = JSON.parse(record.definition).ticket_subject;
          const { domaine, agent, id_user, definition, ...rest } = record;
          const updatedLead = {
            ...rest,
            domaineLead: dom(),
            updated_at: moment().format("LLL"),
            user: record.agent,
            agentId: record.id_user,
            definition: JSON.parse(record.definition),
            subject: subject,
          };
          this.setState((prevState) => {
            return {
              ...prevState,
              data: prevState.data.map((i) =>
                i.id === id ? { ...updatedLead } : { ...i }
              ),
            };
          });
        }

        this.fetchFilterData();
      });
  };

  handleChangeAlert = (id, priority) => {
    axios
      .post(
        BACKEND1 + "/leads/leads/lead",
        {
          setpriority: "yes",
          priority,
          id,
        },
        {
          params: {},
        }
      )
      .then((res) => {
        const { record } = res?.data?.data;
        if (record) {
          const dom = () => {
            try {
              return this.state.domaineOptions.find(
                (i) => i.name === record.domaine
              ).value;
            } catch {
              return null;
            }
          };
          let subject;
          if (record?.definition)
            subject = JSON.parse(record.definition).ticket_subject;
          const { domaine, agent, id_user, definition, ...rest } = record;
          const updatedLead = {
            ...rest,
            domaineLead: dom(),
            updated_at: moment().format("LLL"),
            user: record.agent,
            agentId: record.id_user,
            definition: JSON.parse(record.definition),
            subject: subject,
          };
          this.setState((prevState) => {
            return {
              ...prevState,
              data: prevState.data.map((i) =>
                i.id === id ? { ...updatedLead } : { ...i }
              ),
            };
          });
        }

        this.fetchFilterData();
      });
  };

  closeChangeLeadModal = () => {
    this.setState({
      showEditLead: false,
      error: false,
      selectedLead: {
        id: "",
        domaineLead: { label: "" },
        created: "",
        updated_at: "",
        ticket_id: "",
        user: "",
        agentId: "",
        status: "",
        id_client: "",
        contact: "",
        definition: "",
        fromtrack: "",
        id_contact: "",
        lead_type: "",
      },
    });
  };

  genLang = (text) => {
    const { trad, lang } = this.props;
    return trad["neoleads"][text][lang];
  };

  renderLeadInfo = () => {
    const { selectedLead } = this.state;
    if (!selectedLead) return "";
    if (!selectedLead.definition) return "";
    const { phone, user_email } = selectedLead.definition;
    const { contact } = selectedLead;

    return (
      <div style={{ width: "400px" }}>
        <div>
          <FontAwesomeIcon icon={faUserCircle} color={"#A9A9A9"} />
          {` ${!contact ? "" : contact}`}
        </div>
        <div style={{ marginTop: "0.3rem" }}>
          <FontAwesomeIcon icon={faPhone} color={"#A9A9A9"} />
          {` ${!phone ? "" : phone}`}
        </div>
        <div style={{ marginTop: "0.3rem" }}>
          <FontAwesomeIcon icon={faEnvelope} color={"#A9A9A9"} />
          {` ${!user_email ? "" : user_email}`}
        </div>
      </div>
    );
  };

  urlify = (text) => {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
  };

  getTexturlify = (notes) => {
    if (notes.length > 0) {
      const htmlBlock = this.urlify(notes);
      notes = htmlBlock;
    }

    return notes;
  };

  getDueDate = (ticket_id) => {
    axios
      .get(BACKEND1 + "/fieldValue", {
        params: {
          ticket_id,
          field_id: "166",
          auth_id: localStorage.getItem("user_id"),
        },
      })
      .then((res) => {
        const duedate = res.data.data.value;
        const updatedSelectedLead = { ...this.state.selectedLead, duedate };
        this.setState({ selectedLead: updatedSelectedLead });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  handleFilterSelectChange = (e, field) => {
    console.log(e);
    if (field === "agent") {
      try {
        this.setState({ filterAgent: e.agentId }, () => this.fetchFilterData());
      } catch {
        this.setState({ filterAgent: "" }, () => this.fetchFilterData());
      }
    } else if (field === "status") {
      try {
        this.setState({ filterStatus: e.value }, () => this.fetchFilterData());
      } catch {
        this.setState({ filterStatus: "" }, () => this.fetchFilterData());
      }
    } else if (field === "province") {
      try {
        this.setState({ filterProvince: e.value }, () =>
          this.fetchFilterData()
        );
      } catch {
        this.setState({ filterProvince: "" }, () => this.fetchFilterData());
      }
    } else if (field === "thermo") {
      try {
        this.setState({ filterThermo: e.value }, () => this.fetchFilterData());
      } catch {
        this.setState({ filterThermo: "" }, () => this.fetchFilterData());
      }
    } else if (field === "domaine") {
      try {
        this.setState({ filterDomaine: e.name }, () => this.fetchFilterData());
      } catch {
        this.setState({ filterDomaine: "" }, () => this.fetchFilterData());
      }
    } else if (field === "other") {
      try {
        this.setState({ filterOther: e.value });
      } catch {
        this.setState({ filterOther: "", filterOtherText: "" }, () =>
          this.fetchFilterData()
        );
      }
    }
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );
    }

    const provinceOptions = [
      { label: "Québec", value: "QC" },
      { label: "Ontario", value: "ON" },
    ];

    const columns = [
      // {
      //     Header: 'Lead #',
      //     accessor: 'id',
      // },
      {
        Header: this.genLang("created"),
        accessor: "created",
      },
      {
        Header: this.genLang("subject"),
        accessor: "subjectHover",
      },
      {
        Header: this.genLang("status"),
        accessor: "tableStatus",
      },
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Alert",
        accessor: "alertIcons",
      },
      {
        Header: "Priorité",
        accessor: "priorityIcons",
      },
      {
        Header: this.genLang("domain"),
        accessor: "domaineLead",
      },
      {
        Header: "Province",
        accessor: "province",
      },
      {
        Header: "Edit",
        accessor: "editIcon",
      },
    ];

    const columnsRappels = [
      {
        Header: "Lead #",
        accessor: "id",
      },
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Contact",
        accessor: "contact",
      },
      {
        Header: this.genLang("domain"),
        accessor: "domaineLead",
      },
      {
        Header: "Date",
        accessor: "event_start",
      },
      {
        Header: this.genLang("edit"),
        accessor: "editIcon",
      },
    ];

    const genLang = this.genLang;

    const actionOptions = [
      // {label: "Aucun/None", value: ''},
      { label: genLang("attributed"), value: "1" },
      { label: genLang("supported"), value: "2" },
      { label: genLang("closed"), value: "3" },
      { label: genLang("cancelled"), value: "5" },
      { label: genLang("automaticDistrubution"), value: "0" },
      { label: genLang("notAutomaticDistribution"), value: "4" },
      { label: genLang("inopportun"), value: "7" },
      { label: genLang("ecommercesms"), value: "8" },
    ];
    const otherFilters = [
      // {label: "Aucun", value: ''},
      { label: "Lead #", value: "invId" },
      { label: "Client #", value: "invCliId" },
      { label: "Ticket #", value: "invTicket" },
      { label: "Email", value: "invEmail" },
      { label: "Contact #", value: "invPhone" },
      { label: "Langue", value: "invLang" },
      { label: "Province", value: "invProvince" },
    ];
    const thermoOptions = [
      { label: genLang("cold"), value: "1" },
      { label: genLang("warm"), value: "2" },
      { label: genLang("hot"), value: "3" },
    ];
    const objBig = this.state.dataRawList;
    const showEditLead = this.state.showEditLead;

    return (
      <>
        <Row>
          <Col md={12}>
              <Row>
                <Col md={12}>
                  <Nav
                    style={{
                      paddingTop: "0",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {this.props.tableView === "lead" ? (
                        <div>{`${this.genLang("numberOfLeads")} ${
                          this.state.data.length
                        }`}</div>
                      ) : (
                        <div>{`${this.genLang("numberOfReminders")} ${
                          this.state.rappels.length
                        }`}</div>
                      )}
                      <DownloadCSVButton
                        data={this.state.data.map((i) => {
                          return {
                            lead_id: i.id,
                            created: i.created,
                            status: i.tableStatus,
                            id_client: i.id_client,
                            id_contact: i.contact,
                            agent: i.user,
                            contact: i.contact,
                            domaine: i.domaineLead,
                            subject: i.subject,
                            province: i.province,
                          };
                        })}
                      />
                    </div>
                  </Nav>
                  {!this.state.selectedCheckboxes.length && (
                    <>
                      <h6 style={{ fontWeight: "700", color: "#0052bb" }}>
                        Filtres
                      </h6>
                      <Row style={{ marginBottom: "1rem" }}>
                        <Col md={3}>
                          <Label>Status</Label>
                          <Select
                            options={actionOptions}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isDisabled={this.state.filterLoading === true}
                            isClearable
                            value={
                              actionOptions.find(
                                (i) => i.value === this.state.filterStatus
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "status")
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <Label>Agent</Label>
                          <Select
                            options={this.state.agentOptions}
                            isDisabled={this.state.filterLoading === true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isClearable
                            value={
                              this.state.agentOptions.find(
                                (i) => i.agentId === this.state.filterAgent
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "agent")
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Label>Domaine</Label>
                          <Select
                            options={this.state.domaineOptions}
                            isDisabled={this.state.filterLoading === true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isClearable
                            value={
                              this.state.domaineOptions.find(
                                (i) => i.name === this.state.filterDomaine
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "domaine")
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Label>Province</Label>
                          <Select
                            options={provinceOptions}
                            isDisabled={this.state.filterLoading === true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isClearable
                            value={
                              provinceOptions.find(
                                (i) => i.value === this.state.filterProvince
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "province")
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Label>Thermo</Label>
                          <Select
                            options={thermoOptions}
                            isDisabled={this.state.filterLoading === true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isClearable
                            value={
                              thermoOptions.find(
                                (i) => i.value === this.state.filterThermo
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "thermo")
                            }
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <Label>Autre</Label>
                          <Select
                            options={otherFilters}
                            isDisabled={this.state.filterLoading === true}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                            isClearable
                            value={
                              otherFilters.find(
                                (i) => i.value === this.state.filterOther
                              ) || ""
                            }
                            onChange={(e) =>
                              this.handleFilterSelectChange(e, "other")
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <Label>Value</Label>
                          <Input
                            value={this.state.filterOtherText}
                            disabled={this.state.filterLoading === true}
                            onChange={(e) =>
                              this.setState({ filterOtherText: e.target.value })
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <Button
                            outline
                            disabled={this.state.filterLoading === true}
                            onClick={() => this.fetchFilterData()}
                            style={{ marginTop: "1.6rem", height: "37px" }}
                            color="primary"
                            block
                          >
                            {`${
                              this.state.filterLoading === true
                                ? this.genLang("loading")
                                : this.state.filterAgent === "" &&
                                  this.state.filterStatus === "" &&
                                  this.state.filterOther === "" &&
                                  this.state.filterOtherText === ""
                                ? this.genLang("refresh")
                                : this.genLang("search")
                            } `}
                            {this.state.filterLoading === true ? (
                              <Spinner size="sm" />
                            ) : (
                              <FontAwesomeIcon
                                icon={
                                  this.state.filterAgent === "" &&
                                  this.state.filterStatus === "" &&
                                  this.state.filterOther === "" &&
                                  this.state.filterOtherText === ""
                                    ? faRedo
                                    : faSearch
                                }
                              />
                            )}
                          </Button>
                        </Col>

                        <Col md={3}>
                          <Button
                            outline
                            disabled={
                              this.state.filterLoading === true ||
                              (this.state.filterAgent === "" &&
                                this.state.filterStatus === "" &&
                                this.state.filterOther === "" &&
                                this.state.filterOtherText === "" &&
                                this.state.filterDomaine === "")
                            }
                            onClick={() => {
                              this.setState(
                                {
                                  filterAgent: "",
                                  filterOther: "",
                                  filterStatus: "",
                                  filterDomaine: "",
                                  filterOtherText: "",
                                },
                                () => this.fetchFilterData()
                              );
                            }}
                            style={{ marginTop: "1.6rem", height: "37px" }}
                            color="primary"
                            block
                          >
                            {`${this.genLang("empty")} `}
                            <FontAwesomeIcon icon={faEraser} />
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                  {this.state.selectedCheckboxes.length ? (
                    <ActionBar
                      genLang={this.genLang}
                      trad={this.props.trad}
                      lang={this.props.lang}
                      agentOptions={this.state.agentOptions}
                      domaineOptions={this.state.domaineOptions}
                      selectedCheckboxes={this.state.selectedCheckboxes}
                      fetchFilterData={this.fetchFilterData}
                      handleClearCheckboxes={this.handleClearCheckboxes}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Table
                options={{ headerStyle: { zIndex: 1 } }}
                genLang={this.genLang}
                trad={this.props.trad}
                lang={this.props.lang}
                showCheckboxes={true}
                selectedCheckboxes={this.state.selectedCheckboxes}
                handleCheckboxChange={this.handleCheckboxChange}
                handleSelectAllCheckboxes={this.handleSelectAllCheckboxes}
                data={
                  this.props.tableView === "lead"
                    ? _.map(this.state.data, (elt, i) => {
                        // // console.log(elt)
                        // if (elt.agent === '-1') elt.agent = null
                        elt.priorityIcons = (
                          <PriorityIconsToggle
                            priority={elt.thermo}
                            thermo={elt.thermo}
                            trad={this.props.trad}
                            genLang={this.genLang}
                            lang={this.props.lang}
                            id={elt.id}
                            handleChangePriority={this.handleChangePriority}
                          />
                        );
                        elt.alertIcons = (
                          <AlertIconsToggle
                            priority={elt.priority}
                            thermo={elt.priority}
                            trad={this.props.trad}
                            genLang={this.genLang}
                            lang={this.props.lang}
                            id={elt.id}
                            handleChangeAlert={this.handleChangeAlert}
                          />
                        );
                        elt.tableStatus = this.handleTableStatus(elt);
                        elt.subjectHover = (
                          <div
                            id={`a${elt.id}`}
                            style={{ cursor: "pointer" }}
                            onMouseEnter={(e) => {
                              e.stopPropagation();
                              this.setState({ selectedLead: { ...elt } });
                            }}
                          >
                            {elt.subject}
                            <UncontrolledPopover
                              trigger="hover"
                              target={`a${elt.id}`}
                              placement="left"
                              style={{
                                minHeight: "500px",
                                minWidth: "400px",
                                maxWidth: "800px",
                                maxHeight: "800px",
                              }}
                            >
                              <div
                                style={{
                                  width: "400px",
                                  height: "500px",
                                  backgroundColor: "white",
                                }}
                              >
                                <PopoverHeader>{`Lead #${this.state.selectedLead.id}`}</PopoverHeader>
                                <PopoverBody
                                  style={{
                                    maxWidth: "600px",
                                    maxHeight: "600px",
                                  }}
                                >
                                  {this.state.selectedLead && (
                                    <div
                                      className="messageArchiveContainer"
                                      style={{
                                        marginBottom: "1rem",
                                        maxHeight: "400px",
                                        maxWidth: "400px",
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {this.renderLeadInfo()}
                                      <h6>
                                        {this.state.selectedLead.definition ===
                                        null
                                          ? " "
                                          : this.state.selectedLead.definition
                                              .ticket_latest_public_comment_author_name !==
                                            null
                                          ? `Note par: ${this.state.selectedLead.definition.ticket_latest_public_comment_author_name}`
                                          : " "}
                                      </h6>
                                      {this.state.selectedLead.definition ===
                                      null ? (
                                        <div
                                          className="messageArchive"
                                          dangerouslySetInnerHTML={{
                                            __html: "Aucun Commentaire",
                                          }}
                                        />
                                      ) : this.state.selectedLead.definition
                                          .ticket_latest_public_comment !==
                                        null ? (
                                        this.state.selectedLead.definition
                                          .ticket_latest_public_comment && (
                                          <div
                                            className="messageArchive"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                this.state.selectedLead.definition.ticket_latest_public_comment.replaceAll(
                                                  "\n",
                                                  "<br/>"
                                                ),
                                            }}
                                          ></div>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </PopoverBody>
                              </div>
                            </UncontrolledPopover>
                          </div>
                        );
                        elt.editIcon = (
                          <div style={{ whiteSpace: "nowrap" }}>
                            <IconButton
                              icon={faEdit}
                              tooltip={this.genLang("edit")}
                              style={{ color: "#0076cf" }}
                              onClick={() => this.openChangeLeadModal(elt)}
                            />
                          </div>
                        );

                        return elt;
                      })
                    : this.state.rappels.map((i) => {
                        return {
                          ...i,
                          editIcon: (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <IconButton
                                icon={faInfoCircle}
                                tooltip={this.genLang("viewLead")}
                                style={{ color: "#0076cf" }}
                                onClick={() => this.openChangeLeadModal(i)}
                              />
                            </div>
                          ),
                        };
                      })
                }
                columns={
                  this.props.tableView === "lead" ? columns : columnsRappels
                }
              />
          </Col>
        </Row>

        {showEditLead ? (
          <EditLeadModal
            tableView={this.props.tableView}
            showEditLead={this.state.showEditLead}
            close={this.closeChangeLeadModal}
            loading={this.state.loading}
            error={this.state.error}
            selectedLead={this.state.selectedLead}
            agentOptions={this.state.agentOptions}
            domaineOptions={this.state.domaineOptions}
            handleSelectChange={this.handleSelectChange}
            handleSelectActionChange={this.handleSelectActionChange}
            handleSetValueChange={this.handleSetValueChange}
            handleSubmit={this.handleSubmit}
            handleCloseLead={this.handleCloseLead}
            genLang={this.genLang}
            trad={this.props.trad}
            lang={this.props.lang}
            macrosOptions={this.state.macrosOptions}
            getDueDate={this.getDueDate}
          />
        ) : (
          ""
        )}
        <Loader openx={this.state.isLoaderOpen} />
      </>
    );
  }
}
