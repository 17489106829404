import React, { useMemo } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { genLang } from "../../../../../composables/genLang";
import { jwtDecode } from "jwt-decode";

function TopMenu() {
  const decodedToken = useMemo(
    () => jwtDecode(localStorage.getItem("admin_token")),
    []
  );
  const isAdmin = useMemo(
    () => decodedToken?.scope?.admin === true,
    [decodedToken]
  );

  const menuOptions = [
    ...(isAdmin ? [{ label: "Admin leads", url: "/leads/admin" }] : []),
    ...(isAdmin
      ? [{ label: "Rapport de vente", url: "/salesplanningpeople" }]
      : []),
    {
      label: genLang({ en: "Dashboard", fr: "Tableau de bord" }),
      url: "/dashboard",
      otherUrls: ["/dashboard", "/", "/leads"],
    },
    {
        label: genLang({ en: "Sales to complete", fr: "Ventes à compléter" }),
        url: "/leads/to-complete",
      },
    {
      label: "Contact",
      url: "/contact",
    },
    {
      label: genLang({
        en: "Post sale operations",
        fr: "Opérations après-vente",
      }),
      url: "/basicPostSellactions",
    },
    {
      label: genLang({ en: "Pending sales", fr: "Ventes en attente" }),
      url: "/vwneoventespending",
    },
    {
      label: genLang({ en: "Make a sale", fr: "Faire une vente" }),
      url: "/neoventes",
    },
  ];

  return (
    <div
      className="top-bar color-scheme-light scrollbar-visible"
      style={{
        width: "100%",
        overflowX: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <ul
        style={{
          display: "inline-flex",
          margin: 0,
          padding: 0,
        }}
      >
        {menuOptions.map((e) => {
          const isActive = e?.otherUrls
            ? e?.otherUrls?.includes(window.location.pathname)
            : e.url === window.location.pathname;
          return (
            <li
              key={e.url}
              className={isActive ? "active" : ""}
              style={{
                display: "inline-block",
                whiteSpace: "nowrap",
              }}
            >
              <Link to={e.url} underline="none" style={{ fontSize: "0.85em" }}>
                {e.label}
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="top-menu-controls"></div>
    </div>
  );
}

export default TopMenu;
