import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import React, {
    Component
} from 'react';
import {
    Alert,
    Row,
    Badge,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    NavLink,
    ButtonGroup
    // Table
} from 'reactstrap';
import DashboardElement from '../../../utils/DashboardElement'
// import Dashbo
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Table from '../../../utils/Table';
// import Booking from './Booking'
import TableIcon from '../../../utils/TableIcon';
import UserDetails from './component/UserDetails'
import Tasks from './component/Tasks'
import Widget from './component/Widget'
import AppBar from './component/AppBar'
import UserIcon from './component/UserIcon'
import CenterSection from './component/CenterSection'

import TimelineLead from './component/TimelineLead'
import Reminder from './component/Reminder';
import Contact from './component/Contact';
const profile = require('./thonsid.png');
class ContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'fr'
        }
    }

    toggleLang = () => {
        const lang = this.state.lang === 'fr' ? 'en' : 'fr'
        this.setState({lang: lang});
      }

    render() {
        // return(<>hallo</>)
        return (
            <div

                className='hasSideBar'
                style={{
                    // color:"#ededed",
                    // background: 'repeating-linear-gradient(-45deg, #454545, #454545 10px, #242424 10px, #242424 20px)'
                    //  background: 'repeating-linear-gradient(-45deg, #efefef, #efefef 20px, #fefefe 20px, #fefefe 30px)'
                }}

            // className="menu-w color-style-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link"
            >
                {/* <Badge 
                    onClick={this.toggleLang}
                    className="top-right"
                    style={{position:'fixed',top:'40px',width:'45px',right:0}}
                    >{this.state.lang === 'fr' ? 'EN' : 'FR'}
                </Badge> */}


                <Row>
                    {/* <Col md={3} sm={3} xs={12} style={{
                        // height:'100vh'

                    }}>
                        <UserDetails />
                    </Col> */}
                    <Col md={12} sm={12} xs={12} className="centerApp">
                        <Contact trad={this.props.trad} lang={this.state.lang}/>
                    </Col>
                    {/* <Col 
                        md={3} 
                        sm={3} 
                        xs={12}
                    >
                        <AppBar />
                    </Col> */}
                    {/* <Col 
                        md={3} 
                        sm={4} 
                        xs={12}
                        style={{
                            overflowY:'hidden',
                            maxHeight:'100vh',
                            
                        }} 
                    >
                        <AppBar />
                    </Col> */}
                </Row>
            </div>
        )
    }
}

export default ContactDetails;