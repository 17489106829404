import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import DashboardElement from "../../../utils/DashboardElement";
import Tasks, { CalendarIconButton } from "./component/Tasks";
import CenterSection from "./component/CenterSection";
import "../../../index.css";
import "../../../main.css";
import { NewLeadButton } from "./component/NewLeadButton";

class Lead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: "fr",
    };
  }

  toggleLang = () => {
    const lang = this.state.lang === "fr" ? "en" : "fr";
    this.setState({ lang: lang });
  };

  genLang = (text) => {
    const { trad } = this.props;
    const { lang } = this.state;
    return trad["neoleads"][text][lang];
  };

  render() {
    return (
      <div className="hasSideBar">
        <NewLeadButton floating />
        <Row>
          <Col xs={12} className="centerApp">
            <CenterSection
              lang={this.state.lang}
              trad={this.props.trad}
              {...this.props}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Lead;
