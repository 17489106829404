import React, { useState } from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import _ from "lodash";
import NeoVentesMySWABasicPostActions from "../../../../../components/neoventes/NeoVentesMySWABasicPostActions";
import CenterSectionclose from "./CenterSectionclose";
import AgentDashboard from "./AgentDashboard";
import DashboardElement from "../../../../utils/DashboardElement";
import Tasks, { CalendarIconButton } from "./Tasks";
import { genLang, getLang } from "../../../../../composables/genLang";

function CenterSection() {
  return (
    <Row>
      <Col sm={8}>
        <AgentDashboard />
      </Col>
      <Col sm={4}>
        <DashboardElement
          col={12}
          maxHeight={"400px"}
          title={genLang({ en: "My tasks", fr: "Mes tâches" })}
          headerButton={<CalendarIconButton />}
        >
          <Tasks title={genLang({ en: "My tasks", fr: "Mes tâches" })} />
        </DashboardElement>
      </Col>
    </Row>
  );
}

export default CenterSection;
