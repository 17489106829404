import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import { genLang } from "../../../../../composables/genLang";
import { Tooltip } from "@material-ui/core";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { Fab } from "@mui/material";
const BACKEND1 = process.env.REACT_APP_API_URLX;

export function NewLeadButton() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  function getLead() {
    setLoading(true);
    axios
      .get(BACKEND1 + "/leads", {
        params: {
          newlead: 1, //pour obtenir des un nouveau lead
        },
      })
      .then((status) => {
        let { data } = status.data;
        const { id } = data?.neolead ?? "";

        if (id) {
          history.push(`/leads/${id}`);
        } else {
          toast.error({ en: "No lead found", fr: "Aucun lead trouvé" });
        }
      })
      .catch(function (error) {
        console.log("error object:", error.response.data);
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Tooltip
      placement="right"
      title={genLang({
        en: "Fetch a new Lead",
        fr: "Obtenir un nouveau Lead",
      })}
    >
      <Fab
        color="primary"
        onClick={getLead}
        style={{ position: "fixed", bottom: 5, left: 5 }}
      >
        {loading ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faPlus} />}
      </Fab>
    </Tooltip>
  );
}
