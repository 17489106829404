import React, { useState } from "react";
import { genLang } from "../../composables/genLang";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
const BACKEND1 = process.env.REACT_APP_API_URLX;
export function RemoveFromNonCompletedView({ ticketId, getAllDoneActionsAftersalesList }) {
  const [open, setOpen] = useState(false);
  const handleSubmit = () => {
    axios.post(BACKEND1 + "/ActionAfterSaleCompleted", {
      ticket_id: ticketId,
      auth_id: localStorage.getItem("user_id"),
    }).then((res) => {
        setOpen(false);
        toast.success(genLang({ en: "Removed from non-completed view", fr: "Retiré de la vue non-complétée" }));
        getAllDoneActionsAftersalesList();
    }).catch((err) => {
        console.log(err);
        toast.error(genLang({ en: "Error", fr: "Erreur" }));
    });
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faMinus} style={{ marginRight: "0.5rem" }} />
        {genLang({
          en: "Operations completed",
          fr: "Opération compléteée",
        })}
      </button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <p>
            {genLang({
              en: `Are you sure you want to remove folder ${ticketId} from the non-completed view?`,
              fr: `Êtes-vous sûr de vouloir retirer dossier ${ticketId} de la vue non-complétée?`,
            })}
          </p>
          <div
            style={{ display: "flex", gap: "1rem", alignItems: "baseline" }}
          >
            <button
              type="button"
              className="btn btn-danger btn-block"
              onClick={() => setOpen(false)}
            >
              {genLang({
                en: "Cancel",
                fr: "Annuler",
              })}
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block"
              onClick={handleSubmit}
            >
              {genLang({
                en: "Yes",
                fr: "Oui",
              })}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
